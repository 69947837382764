// Basically this used to handle everything exam related, but the client
// wanted a new feature where pages would be separated by their "course item" (aka competence).
//
// Because of this, renamed this to "ExamContent", and made another component called "Exam"
// that handles a lot of the logic and passes needed props here:
import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Typography } from '@material-ui/core';
import { Pagination, PaginationItem } from '@material-ui/lab';
import { noop } from 'lodash';
import useKeypress from 'react-use-keypress';
import { scroller } from 'react-scroll';
import { QUESTION_SECONDS_PER_PAGE } from '@nereus/shared/constants'

import Loading from 'components/common/Loading';
import { useGlobalDialog } from 'globalDialog';
import Questions from './Questions';
import ConfirmActions from './ConfirmActions';
import Results from './Results';

// const QUESTION_SECONDS_PER_PAGE = 5
// console.log('QUESTION_SECONDS_PER_PAGE', QUESTION_SECONDS_PER_PAGE)

const scrollToTop = () => {
  scroller.scrollTo('top-of-exam', {
    delay: 0,
    smooth: 'easeInOutQuart'
  });
}

// const QUESTION_SECONDS = 60

const ExamContent = ({
  currentPage,
  lastPage,
  setCurrentPage: setCurrentPageNoVerify,
  setAnswer,
  submit,
  submity,
  submitting,
  totalQuestionsCount,
  correctAnswerCount,
  examName,
  subtitle,
  initialized,
  ResultsFooter,
  questions,
  pagination,
  secondsPerQuestion,
  ...rest
}) => {
  // const [timeoutId, setTimeoutId] = useState(null)
  const [questionTime, setQuestionTime] = useState(1)
  const {
    open: openDialog,
    close: closeDialog,
  } = useGlobalDialog();
  const finishedTaking = correctAnswerCount !== null;

  const setCurrentPage = page => {
    scrollToTop();
    setCurrentPageNoVerify(Math.min(Math.max(1, page), lastPage));
  }

  useKeypress(['ArrowLeft', 'ArrowRight'], (event) => {
    if (event.key === 'ArrowLeft') {
      // setCurrentPage(currentPage - 1);
    } else if (event.key === 'ArrowRight') {
      setCurrentPage(currentPage + 1);
    }
  });


  const confirmSubmit = () => {
    openDialog({
      message: 'Submit your answers now?',
      actions: <ConfirmActions closeDialog={closeDialog} submit={submit} />
    });
  }

  const handleChangePage = (_, value) => {setCurrentPage(value);}
  const handleChangeAnswer = (key, event) => setAnswer(key, event.target.value);
  const handleInterval = () => {
    // console.log('Interval', questionTime)
    setQuestionTime(questionTime - 1)
  }

  useEffect(() => {
    if(questionTime < 0) {
      return
    }
    if(questionTime < 1) {
      setCurrentPage(currentPage + 1)
    }
    let timeout = null
    timeout = setTimeout(() => {
      setQuestionTime(questionTime - 1)
    }, 1000) 
    if(currentPage === lastPage && questionTime === 0) {
      // console.info('Auto submit exam', 'current page', currentPage, 'last page', lastPage, 'question time', questionTime)
      submit()
    }
    
    return function () {
      if(timeout) {
        clearTimeout(timeout)
      }
    }
  }, [questionTime])

  useEffect(() => {
    // setQuestionTime(currentPage === lastPage ?QUESTION_SECONDS_PER_PAGE - 1 :QUESTION_SECONDS_PER_PAGE)
    setQuestionTime(secondsPerQuestion)
  }, [currentPage, lastPage])
  return (
    <Loading loading={!initialized}>
      <Grid container spacing={4} className='bg-white rounded py-12 px-5'>
        <Grid item container spacing={0} xs={12} id='top-of-exam'>
          <Grid container item justify='center' spacing={2}>
            {
              !submitting && !finishedTaking && (
                <Grid item xs={12}>
                  <Questions
                    page={currentPage}
                    examName={examName}
                    questions={questions}
                    subtitle={subtitle}
                    time={questionTime}
                    {...rest}
                    handleChangeRadio={handleChangeAnswer}
                  />
                </Grid>)
            }
            {
              !submitting && finishedTaking && (
              <Grid item xs={12}>
                <Results
                  subtitle={examName}
                  questionsCount={totalQuestionsCount}
                  correctAnswerCount={correctAnswerCount}
                  Footer={ResultsFooter}
                />
              </Grid>)
            }
          </Grid>
        </Grid>

        {
          !submitting && !finishedTaking && (
            <Grid item container justify='center' spacing={3} direction='column'>
              {
                (() => {
                  if(pagination == 'page') {
                    return (
                      <Grid item container justify='center' alignItems="center" direction="column" className={`exam-pagination`}>
                        <Pagination
                          page={currentPage}
                          count={lastPage}
                          onChange={handleChangePage}
                          color='primary'
                          renderItem={(item) => (
                            <PaginationItem
                              {...item}
                              disabled={item.page <= currentPage}
                            />
                          )}
                        />
                        <br />
                        {
                          (() => {
                            if(currentPage == lastPage) {
                              return (
                                <Button variant='contained' color='primary' onClick={confirmSubmit}>
                                  Submit
                                </Button>
                              )
                            }
                          })()
                        }
                      </Grid>
                    )
                  }
                  if(pagination == 'next') {
                    return (
                      <Grid item container className={['exam-pagination', 'justify-end']}>
                        {
                          (() => {
                            if(currentPage < lastPage) {
                              return (
                                <Button variant="contained" color="primary" onClick={(e) => handleChangePage(e, currentPage+1)}>Next</Button>
                              )
                            }
                            return (
                              <Button variant='contained' color='primary' onClick={confirmSubmit}>
                                Submit
                              </Button>
                            )
                          })()
                        }
                        
                      </Grid>
                    )
                  }
                })()
              }

              {/*{currentPage === lastPage && (
                <Grid item container justify='center'>
                  <Button variant='contained' color='primary' onClick={confirmSubmit}>
                    Submit
                  </Button>
                </Grid>
              )}*/}
            </Grid>
          )
        }
      </Grid>
    </Loading>
  )
}

ExamContent.propTypes = {
  currentPage: PropTypes.number.isRequired,
  lastPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  setAnswer: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  totalQuestionsCount: PropTypes.number,
  correctAnswerCount: PropTypes.number,
  examName: PropTypes.string,
  subtitle: PropTypes.string,
  initialized: PropTypes.bool,
  Footer: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  pagination: PropTypes.oneOf(['page', 'next'])
}

ExamContent.defaultProps = {
  currentPage: 1,
  lastPage: 9999,
  setCurrentPage: noop,
  setAnswer: noop,
  submit: noop,
  submitting: false,
  totalQuestionsCount: 0,
  correctAnswerCount: 0,
  examName: 'Exam',
  initialized: true,
  pagination: 'page'
}

export default memo(ExamContent);
