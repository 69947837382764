const AUTH_TOKEN = 'TODO-MAYBE-USE-AN-ENV'; // Also TODO why should this be configurable? I forget

const DATE_DISPLAY = 'MMMM D, YYYY';

const DATEPICKER_FORMAT = 'DD/MM/yyyy';

export {
  AUTH_TOKEN,
  DATE_DISPLAY,
  DATEPICKER_FORMAT,
};
