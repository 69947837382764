import { SAVE_ANSWERS, CLEAR_ANSWERS, SET_USER, CLEAR_USER } from '../constants';

const DEFAULT_STATE = {};

export default (state = DEFAULT_STATE, { type, payload }) => {
  switch (type) {
    case SAVE_ANSWERS: {
      return {
        ...state,
        [payload.examKey]: payload.answers,
      };
    }

    case CLEAR_ANSWERS: {
      const { [payload]: omitMe, ...keepMe } = state;

      return keepMe;
    }

    // Logging in or out clears this reducer
    case SET_USER:
    case CLEAR_USER: {
      return DEFAULT_STATE;
    }

    default:
      return state
  }
}
