import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { COURSE_STATUS as STATUS } from '@nereus/shared/constants';
import PrintButton from 'components/common/PrintButton';
import AdminDashboard, {
  PIE_CHART_CONTAINER_MD,
  LONG_CARDS_CONTAINER_MD,
} from 'components/pages/AdminDashboardPage';
import Users from 'components/pages/UsersPage';
import CustomHeader from './CustomHeader';
import Item from './Item';
import CourseApprovals from './CourseApprovals';

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: 32,
    marginRight: 32,
    '@media print': {
      paddingBottom: '0 !important',
      marginTop: 0,
      marginBottom: 0,
      border: 'none',
      overflow: 'hidden',
    },
  },
}));

const ReportPage = () => {
  const classes = useStyles();

  return (<>
    <PrintButton />

    <div className={`${classes.container} bg-gray-200`}>
      <Item>
        <AdminDashboard
          CustomHeader={CustomHeader}
          squareCardProps={{
            setXsTo: 'md',
            labelVariant: 'body1',
            textVariant: 'h4',
          }}
          pieChartContainerProps={{ xs: PIE_CHART_CONTAINER_MD }}
          longCardsContainerProps={{ xs: LONG_CARDS_CONTAINER_MD }}
          longCardProps={{ textVariant: 'body1' }}
        />
      </Item>

      <Item label='Users'>
        <Users disablePagination={true} />
      </Item>

      <Item label='Approved payments'>
        <CourseApprovals
          queryVariables={{ statuses: [STATUS.accepted] }}
          showApprovedDeniedOn={true}
          approvedDeniedOnLabel='Approved On'
        />
      </Item>

      <Item label='Pending payments'>
        <CourseApprovals
          queryVariables={{ statuses: [STATUS.pendingApproval] }}
          showPaymentPostedOn={true}
        />
      </Item>

      <Item label='Rejected payments'>
        <CourseApprovals
          queryVariables={{ statuses: [STATUS.rejected] }}
          showApprovedDeniedOn={true}
          showApproverDenierMessage={true}
          approvedDeniedOnLabel='Rejected On'
          approverDenierMessageLabel='Reason'
        />
      </Item>
    </div>
  </>)
}

export default memo(ReportPage);
