import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse, TableCell, TableRow } from '@material-ui/core';
import { renderComponentOrFunction } from 'utils';

const TableRowCollapse = ({
  row,
  renderRowExpand,
  children,
  numberOfColumns,
}) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpand = () => setExpanded(!expanded);

  if (!renderRowExpand) return children({ toggleExpand });

  return (<>
    {children({ toggleExpand })}
    
    <TableRow>
      <TableCell style={{ padding: 0 }} colSpan={numberOfColumns}>
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          {renderComponentOrFunction(renderRowExpand, {
            row,
            toggleExpand,
          })}
        </Collapse>
      </TableCell>
    </TableRow>
  </>)
}

TableRowCollapse.propTypes = {
  row: PropTypes.object.isRequired,
  renderRowExpand: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  renderRowExpandProps: PropTypes.object,
  numberOfColumns: PropTypes.number,
}

TableRowCollapse.defaultProps = {
  numberOfColumns: 1,
}

export default memo(TableRowCollapse);
