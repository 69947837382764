import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Radio, FormControlLabel } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  correct: {
    color: green[500],
    '&$checked': {
      color: green[600],
    },
  },
  wrong: {
    color: red[500],
  },
}));

const ExamChoice = ({
  id,
  text,
  currentAnswer,
  highlightIfCorrectWrong,
  isThisChoiceCorrect,
  isCorrectChoiceSelected,
}) => {
  const classes = useStyles();

  let colorClass = null;
  if (highlightIfCorrectWrong && currentAnswer !== null) {
    if (isThisChoiceCorrect) {
      colorClass = classes.correct;
    } else if (!isCorrectChoiceSelected) {
      colorClass = classes.wrong;
    }
  }

  return (
    <FormControlLabel
      value={id}
      classes={{ label: colorClass }}
      control={<Radio
        color={colorClass ? 'default' : 'primary'}
        classes={{ checked: colorClass }}
      />}
      label={text}
    />
  )
}

ExamChoice.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  text: PropTypes.string.isRequired,
  currentAnswer: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  highlightIfCorrectWrong: PropTypes.bool,
  isThisChoiceCorrect: PropTypes.bool,
  isCorrectChoiceSelected: PropTypes.bool,
}

ExamChoice.defaultProps = {
  highlightIfCorrectWrong: false,
  isThisChoiceCorrect: false,
  isCorrectChoiceSelected: false, 
}

export default memo(ExamChoice);
