import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import B from 'components/common/B';
import { getSiteVisitors } from 'reduxConfig/selectors';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 12,
    paddingRight: 12,
  },
  label: {
    marginRight: 8,
  },
  count: {
    color: '#2C6DB5',
  },
}));

const SiteVisitors = () => {
  const classes = useStyles();
  const count = useSelector(getSiteVisitors);

  return (
    <span className={classes.container}>
      <B variant='h6' className={classes.label}>
        Site Visitors
      </B>
      
      <B variant='h6' className={classes.count}>
        {count}
      </B>
    </span>
  )
}

export default memo(SiteVisitors);
