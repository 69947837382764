import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from '@material-ui/core';
import {
  Formik,
  Field,
} from 'formik';
import { TextField } from 'formik-material-ui';
import { noop } from 'lodash';
import { useSnackbar } from 'notistack';

import { approveDenyCourseApplication as VALIDATION } from '@nereus/shared/validation';
import { COURSE_APPROVALS as QUERY } from 'constants/queries';
import { APPROVE_DENY_COURSE_APPLICATION as MUTATION } from 'constants/mutations';
import { useMutation } from 'utils';

const DenyModal = ({
  id,
  closeModal,
  ...rest
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [mutate] = useMutation(MUTATION, {
    enqueueSnackbar,
    successMessage: 'Application denied successfully',
    errorMessage: 'Error denying application',
    update(store, { data: { approveDenyCourseApplication: { id }}}) {
      const { courseApprovals } = store.readQuery({ query: QUERY });

      store.writeQuery({ query: QUERY, data: { courseApprovals:
        courseApprovals.filter(x => x.id !== id)
      }});
    },
  });

  return (
    <Dialog
      open={!!id}
      onClose={closeModal}
      fullWidth={true}
      maxWidth='md'
      {...rest}
    >
      <Formik
        initialValues={{ reason: '' }}
        validationSchema={VALIDATION}
        onSubmit={async (variables, { setSubmitting }) => {
          await mutate({ variables: {
            id,
            approve: false,
            approverDenierMessage: variables.reason,
          }});
          
          closeModal();
          setSubmitting(false);
        }}
      >
        {({ submitForm, isSubmitting, isValid }) => (<>
          <DialogContent>
            <Grid container>
              <Field
                name='reason'
                label='Reason'
                fullWidth
                component={TextField}
                variant='outlined'
              />
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button onClick={closeModal} disabled={isSubmitting}>
              Cancel
            </Button>

            <Button
              onClick={submitForm}
              variant='contained'
              color='primary'
              disabled={!isValid || isSubmitting}
              autoFocus
            >
              Submit
            </Button>
          </DialogActions>
        </>)}
      </Formik>
    </Dialog>
  )
}

DenyModal.propTypes = {
  id: PropTypes.number,
  closeModal: PropTypes.func.isRequired,
}

DenyModal.defaultProps = {
  closeModal: noop,
}

export default memo(DenyModal);
