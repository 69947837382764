import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Box,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import ExamMode from 'components/common/ExamMode';
import {
  // NAME_COLUMN_XS,
  // CODE_COLUMN_XS,
  BUTTONS_COLUMN_XS,
} from '../constants';

const useStyles = makeStyles((theme) => ({
  item: {
    marginTop: 8,
    marginBottom: 8,
  },
  typographyName: {
    marginLeft: 8*8,
  },
  // KUPName: {
  //   width: '100%',
  //   marginLeft: (8*8)+20,
  // },
  kups: {
    marginLeft: (8*8)+25,
    listStyleType: 'disc'
  },
  kupCategory: {
    listStyleType: 'circle',
    marginLeft: 20
  }
}));

const CourseCategoryItem = ({
  id,
  name,
  hasExams,
  canReview,
  inReviewMode,
  kupsWithCategory
}) => {
  const history = useHistory();
  const classes = useStyles();

  const handleClick = id => event => {
    event.stopPropagation();

    history.push(`/review/${id}`);
  }

  return (
    <Grid item container xs={12} justify='space-between' classes={{ root: classes.item }}>
      <Grid item xs={12} className={['flex', 'justify-between']}>
        <Typography variant='subtitle1' className={classes.typographyName}>
          {name}
        </Typography>
        {
          inReviewMode && (
            <ExamMode.Hide>
              <Button
                key={id}
                variant='outlined'
                onClick={handleClick(id)}
                disabled={!canReview || !hasExams}
              >
                Review
              </Button>
            </ExamMode.Hide>
          )
        }
      </Grid>
      {
        kupsWithCategory.length > 0 &&
        <Grid item container xs={12}>
          <ul className={classes.kups}>
            {
              kupsWithCategory.map((kupCat, index) => (
                <li key={"kup-"+index}>
                  <Typography variant='subtitle1'>
                    {kupCat.text}
                  </Typography>
                  {
                    kupCat.categories.length > 0 && 
                    <ul className={classes.kupCategory}>
                      {
                        kupCat.categories
                          .map((cat, i) => <li key={"kupcat-"+i}>{cat}</li>)
                      }
                    </ul>
                  }
                </li>
              ))
            }
          </ul>
        </Grid>
      }
    </Grid>
  )
}

CourseCategoryItem.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  name: PropTypes.string.isRequired,
  hasExams: PropTypes.bool,
  canReview: PropTypes.bool,
  inReviewMode: PropTypes.bool,
}

CourseCategoryItem.defaultProps = {
  hasExams: false,
  canReview: false,
  inReviewMode: false,
}

export default memo(CourseCategoryItem);
