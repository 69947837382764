import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from './reducers';
import { checkInDevMode } from 'utils';

export default preloadedState => {
  const persistConfig = { key: 'root', storage };
  const persistedReducer = persistReducer(persistConfig, rootReducer)

  let store = configureStore({
    reducer: persistedReducer,
    preloadedState,
    // https://github.com/rt2zz/redux-persist/issues/988#issuecomment-654875104
    middleware: getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"],
      },
    })
  });  

  if (checkInDevMode() && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
  }

  let persistor = persistStore(store);

  return { store, persistor };
}
