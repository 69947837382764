import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, List, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isObject } from 'lodash';
import B from 'components/common/B';

const useStyles = makeStyles((theme) => ({
  img: {
    objectFit: 'contain',
    maxHeight: 80,
  },
}));

const centerGridProps = {
  container: true,
  item: true,
  alignItems: 'center',
  justify: 'center',
}

const PaymentOption = ({
  img,
  name,
  info,
}) => {
  const classes = useStyles();

  const rows = [].concat(info).map(row =>
    isObject(row) ? row : { value: String(row) }
  );

  return (
    <Grid container item xs={12} spacing={4}>
      <Grid {...centerGridProps} xs={4}>
        <img src={`/images/vendor_logos/${img}`} alt={name} className={classes.img} />
      </Grid>

      <Grid {...centerGridProps} xs={8} direction='column'>
        <Grid>
          <Typography variant='h5' className='font-bold'>{name}</Typography>
        </Grid>

        <Grid {...centerGridProps}>
          <List>
            {rows.map(row => (<PaymentOptionRow key={row.value} {...row} />))}
          </List>
        </Grid>
      </Grid>
    </Grid>
  )
}

const PaymentOptionRow = ({ value, label }) => {
  if (!label) {
    return (
      <ListItem>
        <Typography variant='h6'>{value}</Typography>
      </ListItem>
    )
  }
  
  return (
    <ListItem>
      <B variant='h6' nowrap>{label}</B>&nbsp;
      <Typography variant='h6'>{value}</Typography>
    </ListItem>
  )
}

PaymentOption.propTypes = {
  img: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  info: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string,
      }),
    ]))
  ]).isRequired,
}

export default memo(PaymentOption);
