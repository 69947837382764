import { memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import { GET_USER_TAKING_EXAM_FOR_COURSE_ID as QUERY } from 'constants/queries';
import { updateUserTakingExamForCourseId } from 'reduxConfig/actions/currentUser';
import { getAuthToken, checkIfUserCurrentlyTakingExam } from 'reduxConfig/selectors';
import { useQuery } from 'utils';

const ExamModeShowHide = ({ children, requiredExamMode }) => {
  const dispatch = useDispatch();
  const token = useSelector(getAuthToken);
  const isUserCurrentlyTakingExam = useSelector(checkIfUserCurrentlyTakingExam);

  const skipExamModeCheck = !token || requiredExamMode === undefined;

  // This is probably redundant and can likely be removed
  const { loading } = useQuery(QUERY, {
    skip: skipExamModeCheck,
    autoSuccessErrorMessages: false,
    fetchPolicy: 'cache-first',
    onCompleted: data => {
      dispatch(updateUserTakingExamForCourseId(get(data, 'currentUser.takingExamForCourseId')));
    },
  });
  
  if (skipExamModeCheck || (!loading && requiredExamMode === isUserCurrentlyTakingExam)) {
    return children;
  }

  return null;
}

ExamModeShowHide.propTypes = {
  requiredExamMode: PropTypes.bool.isRequired,
}

export default memo(ExamModeShowHide);
