import React, {} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';


function DetailDialog ({
  detail,
  open,
  onClose
}) {

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" style={{width: '100%'}}>
      <DialogTitle id="form-dialog-title">Examinee information</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6">Graduated from</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>{window._.get(detail, 'graduatedFrom', 'n/a')}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6">Graduated on</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>{moment(window._.get(detail, 'graduatedOn', new Date())).format('DD-MM-YYYY')}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6">Birthday</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>{window._.get(detail, 'birthday', 'n/a')}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6">Address</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>{window._.get(detail, 'address', 'n/a')}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6">Company</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>{window._.get(detail, 'company', 'n/a')}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6">Referred by</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>{window._.get(detail, 'referredBy', 'n/a')}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6">Admin</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>{window._.get(detail, 'isAdmin', false) ?'Yes' :'No'}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
DetailDialog.propTypes = {
  detail: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func
}
DetailDialog.defaultProps = {
  open: false,
  onClose: () => {}
}

export default DetailDialog