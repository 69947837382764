import React, { memo } from 'react';
import { Box, Typography } from '@material-ui/core';

const B = ({ children, ...rest }) => (
  <Typography {...rest}>
    <Box component='span' fontWeight='fontWeightBold'>
      {children}
    </Box>
  </Typography>
)

export default memo(B);
