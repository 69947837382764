import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import RetryIcon from '@material-ui/icons/Replay';

const LoadingRetryButton = ({ refetch }) => {
  if (!refetch) return null;

  // Reminder: Do not pass event
  const handleClick = () => refetch();

  return (
    <IconButton onClick={handleClick}>
      <RetryIcon />
    </IconButton>
  )
}

LoadingRetryButton.propTypes = {
  refetch: PropTypes.func,
}

export default memo(LoadingRetryButton);
