import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isString } from 'lodash';
import useGlobalDialog from './useGlobalDialog';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    padding: 16,
    maxWidth: 700,  // for comparison, sm=600, md=960
  },
}));

export default () => {
  const classes = useStyles();
  const {
    isOpen,
    message,
    title,
    actions,
    close,
  } = useGlobalDialog();

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      aria-labelledby='global-dialog-title'
      aria-describedby='global-dialog-description'
      classes={{ paper: classes.dialogPaper }}
    >
      {
        title && (
          <DialogTitle disableTypography={!isString(title)}>
            {title}
          </DialogTitle>
        )
      }

      <DialogContent>
        {message}
      </DialogContent>

      {
        actions && (
          <DialogActions>
            {actions}
          </DialogActions>
        )
      }
    </Dialog>
  );
}
