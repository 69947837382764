import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
// import { KeyboardDatePicker, TimePicker } from '@material-ui/pickers'
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useSnackbar } from 'notistack';
// import moment from "moment";
import { useQuery, useMutation } from 'utils';
import Table from 'components/common/Table';
// import Loading from 'components/common/Loading';
import { GET_EXAM_SCHEDULE as QUERY } from 'constants/queries';

// import { login as LOGIN_VALIDATION } from '@nereus/shared/validation';
// import { LOGIN as LOGIN_MUTATION } from 'constants/mutations';
// import { login as loginAction } from 'reduxConfig/actions/currentUser';
// import Field from './_Field';
// import Form from './_Form';
import { UPDATE_USERCOURSE_SCHED, ADD_USER_SCHEDULE } from 'constants/mutations';

const useStyles = makeStyles((theme) => ({
  image: {
    backgroundImage: "url('/images/main_background.png')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    paddingLeft: 40,
    paddingRight: 40,
    minHeight: '100vh',
  },
}));

const SetSchedModal = ({
  closeDialog,
  userId,
  id,
}) => {
  // const classes = useStyles();
  // const history = useHistory();
  // const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleClickConfirm = (x,y,z) => {
    console.log(parseInt(x),parseInt(userId),y+' - '+z);
    // evt.preventDefault();
    mutation({
      variables: {
        id: x,
        userId: userId,
        schedule: y+' - '+z,
      },
    });

    // mutation1({
    //   variables: {
    //     examschedId: parseInt(x),
    //     usercourseId: parseInt(id),
    //   },
    // });
  }
  
  const [mutation, { loading: isLoading }, mutationResults] = useMutation(
    UPDATE_USERCOURSE_SCHED,
    {
      onCompleted: (data) => {
        closeDialog();
        console.log(data);
        // window.location='/admin/approve-deny-payments';
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );
  
  const [mutation1, { loading: isLoading1 }, mutationResults1] = useMutation(
    ADD_USER_SCHEDULE,
    {
      onCompleted: (data) => {
        closeDialog();
        console.log(data);
        window.location='/admin/approve-deny-payments';
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  const { data, LoadingProps } = useQuery(QUERY, {
    variables: enqueueSnackbar,
    errorMessage: `Error loading course approvals`,
  });
  const rows = get(data, 'getExamSchedule', []);
  // const rows = '';

  return (

      <Table
        rows={rows}
        columns={[
          { field: 'exameDate', label: 'Exam Date'},
          { field: 'examTime', label: 'Exam Time'},
          { field: 'examTotal', label: 'Slots' },
          { label: 'Actions', render: row => {
            // if (row.status !== STATUS.pendingApproval) return (<></>);
            
            return (<>
              {/* <p>Test {row.id+' '+row.exameDate}</p> */}
              <Button
                variant='contained'
                className='bg-green-400 text-white'
                onClick={() => handleClickConfirm(id, row.exameDate, row.examTime)}
              >
                Assign
              </Button>
            </>)
          }},
        ]}
      />
  )
}

export default memo(SetSchedModal);
