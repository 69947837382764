import React, { memo } from 'react';
import WelcomeHeader from 'components/common/WelcomeHeader';

const CourseHeader = () => {
  return (
    <WelcomeHeader>
      Select a course to review and enroll
    </WelcomeHeader>
  )
}

export default memo(CourseHeader);
