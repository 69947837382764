import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

const Actions = ({
  takeExam,
  closeDialog,
  confirmText,
  cancelText,
}) => {
  const handleClickConfirm = () => {
    closeDialog();
    takeExam();
  }

  return (<>
    <Button
      variant='contained'
      className='bg-green-400 text-white'
      onClick={handleClickConfirm}
    >
      {confirmText}
    </Button>

    <Button
      variant='contained'
      onClick={closeDialog}
      autoFocus
    >
      {cancelText}
    </Button>
  </>)
}

Actions.propTypes = {
  takeExam: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
}

Actions.defaultProps = {
  confirmText: 'TAKE EXAM',
  cancelText: 'TAKE EXAM LATER',
}

export default memo(Actions);
