import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { GET_USER_TAKING_EXAM_FOR_COURSE_ID as QUERY } from 'constants/queries';
import { TOGGLE_EXAM_MODE as MUTATION } from 'constants/mutations';
import { useMutation } from 'utils';

const ExitExamModeButton = ({ disabled }) => {
  const { enqueueSnackbar } = useSnackbar();
  
  const [mutate] = useMutation(MUTATION, {
    enqueueSnackbar,
    successMessage: null,
    errorMesage: 'Error sending request to server',
    update: store => {
      store.writeQuery({ query: QUERY, data: { currentUser: { takingExamForCourseId: null }}});
    },
  });

  const handleClick = event => {
    event.stopPropagation();
    mutate();
  }
  
  return (
    <Button
      variant='contained'
      className={!disabled ? 'bg-green-400 text-white' : 'bg-gray'}
      onClick={handleClick}
      disabled={disabled}
    >
      Exit exam page
    </Button>
  )
}

ExitExamModeButton.propTypes = {
  disabled: PropTypes.bool,
}

ExitExamModeButton.defaultProps = {
  disabled: false,
}

export default memo(ExitExamModeButton);
