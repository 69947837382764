import { createMachine, assign } from 'xstate'

export default createMachine({
  id: 'UserPage',
  type: 'parallel',
  context: {
    detail: null,
    examinee: null
  },
  states: {
    detail: {
      initial: 'close',
      states: {
        close: {
          on: {
            DETAIL_TOGGLE: {
              target: 'open',
              actions: ['setDetail']
            }
          }
        },
        open: {
          on: {
            DETAIL_TOGGLE: 'close'
          }
        },
      }
    },
    result: {
      initial: 'close',
      states: {
        close: {
          on: {
            RESULT_TOGGLE: {
              target: 'open',
              actions: ['setExaminee']
            }
          }
        },
        open: {
          on: {
            RESULT_TOGGLE: {
              target: 'close',
              actions: assign({
                examinee: null
              })
            }
          }
        }
      }
    }
  }
}, {
  actions: {
    setExaminee: assign({
      examinee(ctx, event) {
        return event.data
      }
    }),
    setDetail: assign({
      detail(ctx, event) {
        return event.data
      }
    })
  }
})