import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { noop } from 'lodash';
import { useSnackbar } from 'notistack';

import { COURSE_STATUS as STATUS } from '@nereus/shared/constants';
import { COURSES_WITH_USER_COURSE as QUERY } from 'constants/queries';
import { APPLY_COURSE as MUTATION } from 'constants/mutations';
import { checkIfCanAlwaysAccessCourse } from 'reduxConfig/selectors';
import { useMutation } from 'utils';

const CourseActionButton = ({
  course,
  status,
  setCourseUploadingFor,
  hideActionButtonsIfAccepted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const canAlwaysAccessCourse = useSelector(checkIfCanAlwaysAccessCourse);

  const [mutate] = useMutation(MUTATION, {
    enqueueSnackbar,
    successMessage: 'Course added successfully',
    errorMessage: 'Error adding course',
    update(store, { data: { applyCourse: updatedCourse }}) {
      const { courses } = store.readQuery({ query: QUERY });

      store.writeQuery({ query: QUERY, data: { courses: courses.map(x => ({
        ...x,
        ...(x.id === updatedCourse.id ? updatedCourse : {}),
      }))}});
    },
  });

  const handleClick = event => {
    event.stopPropagation();
    
    if (!status) {
      mutate({ variables: { courseId: Number(course.id) }});
      
    } else if ([
      STATUS.pendingPayment,
      STATUS.pendingApproval,
      STATUS.rejected,
    ].includes(status)) {
      setCourseUploadingFor(course);
    }
  }

  let text = 'Add Course';
  let disabled = false;
  let color = 'primary';

  if (canAlwaysAccessCourse || status === STATUS.accepted) {
    if (hideActionButtonsIfAccepted) return null;
    
    text = 'Accepted';
    disabled = true;

  } else if (status === STATUS.pendingPayment) {
    text = 'Pay Course';
    // color = '#FCD34D';
    color = 'secondary';

  } else if (status === STATUS.pendingApproval) {
    text = 'Pending';
    disabled = true; // Allowed in the backend technically

  } else if (status === STATUS.rejected) {
    text = 'Rejected';
    color = 'secondary';
  }

  return (
    <Button
      variant='contained'
      color={color}
      className='ml-3'
      onClick={handleClick}
      disabled={disabled}
    >
      {text}
    </Button>
  )
}

CourseActionButton.propTypes = {
  course: PropTypes.object.isRequired,
  setCourseUploadingFor: PropTypes.func,
  status: PropTypes.string,
  hideActionButtonsIfAccepted: PropTypes.bool,
}

CourseActionButton.defaultProps = {
  setCourseUploadingFor: noop,
  hideActionButtonsIfAccepted: false,
}

export default memo(CourseActionButton);
