import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';

import B from 'components/common/B';
import Container from './Container';
import RetryButton from './RetryButton';

const Loading = ({
  loading,
  inCache,
  error,
  errorMessage,
  refetch,
  padding,
  children
}) => {
  if (!inCache && loading) {
    return (
      <Container padding={padding}>
        <CircularProgress />
      </Container>
    )
  }

  if (error) {
    return (
      <Container padding={padding}>
        <B>{errorMessage}</B>
        <RetryButton refetch={refetch} />
      </Container>
    )
  }

  return children;
}

Loading.propTypes = {
  loading: PropTypes.bool,
  inCache: PropTypes.bool,
  error: PropTypes.object,
  errorMessage: PropTypes.string,
  refetch: PropTypes.func,
  padding: PropTypes.number,
}

Loading.defaultProps = {
  loading: true,
  inCache: false,
  errorMessage: 'Error loading data',
  padding: 8*4,
  children: null,
}

export default memo(Loading);
