import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSnackbar } from 'notistack';

import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { USERS as QUERY } from 'constants/queries';
import Table from 'components/common/Table';
import Loading from 'components/common/Loading';
import Details from './Details';
import ResultDialog from './ResultDialog'
import DetailDialog from './DetailDialog'
import { displayDate, useQuery } from 'utils';

import { useMachine } from '@xstate/react'
import Machine from './UsersPage.machine'

const d = x => {
  return displayDate(get(x, 'value', x))
}

const getFullName = row => {
  const firstName = get(row, 'firstName', null);
  const middleName = get(row, 'middleName', null);
  const lastName = get(row, 'lastName', null);
  
  return [
    firstName,
    middleName,
    lastName,
  ].filter(x => x).join(' ');
}

const Users = ({ disablePagination, ...rest }) => {
  const PageMachine = useMachine(Machine)
  const examinee = PageMachine[0].context.examinee

  const { enqueueSnackbar } = useSnackbar();

  const { data, LoadingProps } = useQuery(QUERY, {
    enqueueSnackbar,
    errorMessage: 'Error loading list of users',
  });
  
  const rows = get(data, 'users', []);

  const handleShowResult = (row) => {
    PageMachine[1]({type: 'RESULT_TOGGLE', data: row})
  }

  const handleShowInfo = (row) => {
    PageMachine[1]({type: 'DETAIL_TOGGLE', data: row})
  }

  //Tried DataGrid, did not like it, keeping it here just in case for now:
  // return (
  //   <div style={{ height: '100%', width: '100%' }}>
  //     <DataGrid rows={rows} pageSize={10} columns={[
  //       { field: 'username', headerName: 'Username' },
  //       { field: 'email', headerName: 'Email' },
  //       { field: 'firstName', headerName: 'First Name', width: 200 },
  //       { field: 'middleName', headerName: 'Middle Name' },
  //       { field: 'lastName', headerName: 'Last Name' },
  //       { field: 'graduatedFrom', headerName: 'Graduated From' },
  //       { field: 'graduatedOn', headerName: 'Graduated On', valueFormatter: d },
  //       { field: 'birthday', headerName: 'Birthday', valueFormatter: d },
  //       { field: 'address', headerName: 'Address' },
  //       { field: 'phoneNumber', headerName: 'Phone' },
  //       { field: 'company', headerName: 'Company' },
  //       { field: 'referredBy', headerName: 'Referred By' },
  //       { field: 'rank', headerName: 'Rank', valueGetter: params => get(params.value, 'name', '') },
  //       { field: 'isAdmin', headerName: 'Admin?', valueFormatter: x => x ? 'yes' : 'no' },
  //       { field: 'Created On', headerName: 'createdOn', valueFormatter: d },
  //     ]}/>
  //   </div>
  // )

  // username, email, full name, rank, phone, date registered

  return (
    <Loading {...LoadingProps}>
      <DetailDialog
        detail={PageMachine[0].context.detail}
        open={PageMachine[0].matches('detail.open')}
        onClose={() => PageMachine[1]('DETAIL_TOGGLE')}
      />
      <ResultDialog
        examinee={examinee}
        open={PageMachine[0].matches('result.open')}
        onClose={() => PageMachine[1]('RESULT_TOGGLE')}
      />
      <Table
        rows={rows}
        columns={[
          { field: 'username' },
          { field: 'email' },
          { label: 'Name', get: getFullName },
          { label: 'Rank', get: row => get(row, 'rank.name', '') },
          { field: 'phoneNumber', label: 'Phone' },
          { field: 'createdOn', label: 'Date Registered', format: d },
          { field: 'actions', label: 'Actions', get: row => {
            {/*console.log(row)*/}
            return (
              <Box>
                {
                  !row.isAdmin && (
                    <Box>
                      <Tooltip title="See info">
                        <IconButton color="primary" onClick={() => handleShowInfo(row)}>
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="See result">
                        <IconButton color="primary" onClick={() => handleShowResult(row)}>
                          <PlaylistAddCheckIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )
                }
              </Box>
            )
          } },
        ]}
        disablePagination={disablePagination}
        {...rest}
      />
    </Loading>
  )
}

Users.propTypes = {
  disablePagination: PropTypes.bool,
}

Users.defaultProps = {
  disablePagination: false,
}

export default memo(Users);
