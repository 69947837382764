import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import Question from './Question';
import { Button, Grid, Typography, Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles'
import moment from 'moment'

const ExamQuestions = ({
  subtitle,
  questions,
  userQA,
  liveCheckingResults,
  firstQuestionNumber,
  examName,
  page,
  time,
  ...rest
}) => {
  const theme = useTheme()

  return (
  <Grid container>
    <Grid item xs={12}>
      <Box style={{
        display: 'flex',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        marginLeft: -(theme.spacing(4) + 4),
        marginRight: -(theme.spacing(4) + 4),
        marginTop: '-63px',
        padding: theme.spacing(3),
        marginBottom: theme.spacing(2)
      }}>
        <Box style={{flexGrow: 1}}>
          {
            examName && (
              <Typography variant='h5' align="left" className='font-bold'>
                {examName}
              </Typography>
            )
          }
          <Typography variant='h6' mt={6} align="left" className='font-bold'>
            {subtitle}
          </Typography>
        </Box>
        {
          time > 0 && (
            <Box style={{
              display: 'flex',
              alignItems: 'center'
            }}>
              <Typography variant="h4" align="center">
                {time}
              </Typography>
            </Box>
          )
        }
      </Box>
    </Grid>
  { questions.map(({ id, text, choices = []}, idx) => {
    const questionNumber = idx + firstQuestionNumber;
    const currentAnswer = userQA[id] || null;
    const isCorrectChoiceSelected = liveCheckingResults[id] === currentAnswer;

    return (
      <Question
          key={id}
          {...rest}
          page={page}
          id={id}
          text={text}
          choices={choices}
          questionNumber={questionNumber}
          currentAnswer={currentAnswer}
          isCorrectChoiceSelected={isCorrectChoiceSelected}
      />
    )
  })}
  </Grid>
  );
}

ExamQuestions.propTypes = {
  page: PropTypes.number.isRequired,
  questions: PropTypes.array,
  userQA: PropTypes.object,
  liveCheckingResults: PropTypes.object,
  handleChangeRadio: PropTypes.func,
  firstQuestionNumber: PropTypes.number,
  examName: PropTypes.string,
  time: PropTypes.string
}

ExamQuestions.defaultProps = {
  questions: [],
  userQA: {},
  liveCheckingResults: {},
  handleChangeRadio: noop,
  firstQuestionNumber: 1,
  time: false
}

export default memo(ExamQuestions);
