import React, { memo } from 'react';
import { Grid } from '@material-ui/core';
import { isString } from 'lodash';
import { useSelector } from 'react-redux';
import { getCurrentUsername } from 'reduxConfig/selectors';

const childrenWrapper = text => (
  <Grid item xs={12}>
    <h3 className="text-xl font-medium text-blue-600">
      {text}
    </h3>
  </Grid>
)

const WelcomeHeader = ({ children }) => {
  const name = useSelector(getCurrentUsername);

  return (
    <Grid container item>
      <Grid item xs={12}>
        <h2 className="text-3xl font-medium text-gray-700">Welcome, {name}!</h2>
      </Grid>
      
      {children && (isString ? childrenWrapper(children) : children)}
    </Grid>
  );
}

export default memo(WelcomeHeader);
