import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import RouteContext from 'components/Route/context';

const useStyles = makeStyles((theme) => ({
  hideWhilePrintingUsingCss: {
    '@media print': {
      display: 'none',
    },
  },
  testRed: {
    backgroundColor: 'red',
  },
}));

const NoPrint = ({ children, enableCssFallback }) => {
  const classes = useStyles();

  // Note that this relies on this flag being set by clicking <PrintButton />
  // If the user trying to print by just doing ctrl+P is a concern,
  // then keep "enableCssFallback" at true.
  // But if the <span/> around the children is an issue, set it to false.
  const { isPrinting } = useContext(RouteContext);

  if (isPrinting) return null;

  if (enableCssFallback) {
    return (
      <span className={classes.hideWhilePrintingUsingCss}>
        {children}
      </span>
    )
  }

  return children;
}

NoPrint.propTypes = {
  enableCssFallback: PropTypes.bool,
}

NoPrint.defaultProps = {
  enableCssFallback: true,
}

export default memo(NoPrint);
