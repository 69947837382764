import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import { displayDate } from 'utils';

const d = x => {
  return displayDate(get(x, 'value', x))
}

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    padding: 8*2,
    cursor: 'pointer',
  },
}));

const Item = memo(({ label, value }) => (
  <Typography>
    <Box component='span' fontWeight='fontWeightBold'>
      {label}:&nbsp;
    </Box>
    
    <Box component='span'>{value || ''}</Box>
  </Typography>
))

const UserDetails = ({
  row,
  toggleExpand,
}) => {
  const classes = useStyles();
  
  return (
    <Paper
      classes={{ root: classes.paperContainer }}
      onClick={toggleExpand}
    >
      <Item label={'Graduated from'} value={row.graduatedFrom} />
      <Item label={'Graduated on'} value={d(row.graduatedOn)} />
      <Item label={'Birthday'} value={d(row.birthday)} />
      <Item label={'Address'} value={row.address} />
      <Item label={'Company'} value={row.company} />
      <Item label={'Referred by'} value={row.referredBy} />
      <Item label={'Admin'} value={row.isAdmin ? 'yes' : 'no'} />
    </Paper>
  )
}

UserDetails.propTypes = {
  row: PropTypes.object.isRequired,
  toggleExpand: PropTypes.func.isRequired,
}

export default memo(UserDetails);
