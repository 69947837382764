import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 12,
    paddingRight: 12,
  },
  rectangle: {
    height: 16,
    width: 24,
    backgroundColor: props => props.color,
    marginRight: 4,
  },
  color: {
    color: props => props.color,
  },
}));

const PieChartLabel = ({ label, color }) => {
  const classes = useStyles({ color });

  return (
    <div className={classes.container}>
      <div className={classes.rectangle} />
      
      <Typography>{label}</Typography>
    </div>
  )
}

PieChartLabel.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
}

export default memo(PieChartLabel);
