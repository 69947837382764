import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const HEIGHT = 128;
const HIGHTLIGHT_PX = 6;

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    minHeight: HEIGHT,
  },
  cardOuter: {
    flexDirection: 'column',
    justifyContent: 'flex-end',
    backgroundColor: props => props.color || 'blue',
    paddingTop: HIGHTLIGHT_PX,
  },
  cardInner: {
    paddingLeft: 32,
    paddingRight: 32,
    backgroundColor: theme.palette.background.paper,
  },
  number: {
    color: props => props.color || 'blue',
  },
}));

const SquareCard = ({
  text,
  setXsTo,
  children,
  color,
  hide,
  labelVariant,
  textVariant,
  ...rest
}) => {
  const classes = useStyles({ color });

  if (hide) return null;

  return (
    <Grid item {...rest} xs={rest[setXsTo]}>
      <Paper className={`${classes.card} ${classes.cardOuter}`}>
        <Grid
          container
          direction='column'
          justify='center'
          classes={{ root: `${classes.card} ${classes.cardInner}`}}
        >
          <Grid container item justify='center'>
            <Typography variant={labelVariant} align='center'>
              {text}
            </Typography>
          </Grid>

          <Grid container item justify='center'>
            <Typography variant={textVariant} align='center' className={classes.number}>
              {children}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

SquareCard.propTypes = {
  text: PropTypes.string.isRequired,
  setXsTo: PropTypes.string,
  xs: PropTypes.number,
  color: PropTypes.string,
  labelVariant: PropTypes.string,
  textVariant: PropTypes.string,
  hide: PropTypes.bool,
}

SquareCard.defaultProps = {
  children: 0,
  setXsTo: 'xs',
  xs: 6,
  color: 'blue',
  labelVariant: 'h6',
  textVariant: 'h3',
  hide: false,
}

export default memo(SquareCard);
