import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const Paragraphs = ({ data, Component, ...rest }) => {
  return data.map((paragraph, i) => (
    <Component key={i} paragraph={true} {...rest}>
      {paragraph}
    </Component>
  ))
}

Paragraphs.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
}

Paragraphs.defaultProps = {
  data: [],
  Component: Typography,
}

export default memo(Paragraphs);
