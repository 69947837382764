import React, { useContext } from 'react';
import { isObject } from 'lodash';
import context from './context';
import DefaultActions from './DefaultActions';

export default () => {
  const {
    isOpen,
    setOpen,
    message,
    setMessage,
    title,
    setTitle,
    actions,
    setActions,
  } = useContext(context);

  const close = () => {
    setOpen(false);
  }

  const open = (options = {}) => {
    const {
      message = '',
      title = '',
      actions = <DefaultActions close={close} />,
    } = isObject(options) ? options : { message: String(options) };

    setOpen(true);
    setMessage(message);
    setTitle(title);
    setActions(actions);
  }

  return {
    isOpen,
    message,
    title,
    actions,
    open,
    close,
  };
}
