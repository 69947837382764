import React, { memo, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get, noop, parseInt, shuffle } from 'lodash';
import { useSnackbar } from 'notistack';

import { EXAM_TYPE } from '@nereus/shared/constants';
import Exam from 'components/common/Exam';
import Loading from 'components/common/Loading';
import { BEGIN_EXAM, ANSWER_EXAM, COMP_USER_SCORE } from 'constants/mutations';
import { checkIfExamCheatsEnabled } from 'reduxConfig/selectors';
import { useMutation, performChecking } from 'utils';

import { getCurrentUserID } from 'reduxConfig/selectors';

const ExamPage = () => {
  const history = useHistory();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const examCheatsEnabled = useSelector(checkIfExamCheatsEnabled);
  // const [ cid, setCid ] = useState();
  // const [ cscore, setCScore ] = useState();
  const uid = useSelector(getCurrentUserID);
  //^admins get the correct answers in the response
  // so we can enable live checking for them
  const examId = Number(params.examId);

  const [beginExam, { loading, error, data }] = useMutation(BEGIN_EXAM, {
    enqueueSnackbar,
    successMessage: null,
    errorMessage: 'Error starting exam',
    variables: { examId },
    onError: (e, g) => {
      g(e);
      history.push(`/`);
    },
  });
  useEffect(() => { beginExam(); return noop; }, [beginExam]);

  const handleClickConfirm = (cid, uid, cscore) => {
    mutation({
      variables: {
        courseItemID: parseInt(cid),
        userid: parseInt(uid),
        score: parseInt(cscore),
        examId: parseInt(examId),
      },
    });
  }

  const [mutation, {}] = useMutation(
    COMP_USER_SCORE,
    {
      onCompleted: (data) => {
        console.log(data);
        window.location='/exam';
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  // <Exam /> handles success/errors in this case:
  const [answerExam] = useMutation(ANSWER_EXAM, { autoSuccessErrorMessages: false });

  const examName = get(data, 'beginExam.exam.name', 'Exam');
  const qas = get(data, 'beginExam.qas', []);
  const secondsPerQuestion = get(data, 'setting.secondsPerQuestion', 60)
  const questions = qas.map(qa => {
    const randomChoices = qa.question.choices
    return {
      ...qa.question,
      choices: shuffle(randomChoices),
      id: qa.id,
    }
  });

  const handley = async ({ userQA = {} }, sid, temporary) => {
    const answers = Object.entries(userQA)
      .map(([qasId, choiceId]) =>
        ({ qasId: Number(qasId), choiceId: Number(choiceId) }));
    // console.log('Temporary: '+temporary);
    const response = await answerExam({ variables: { examId, answers, temporary }});

    // setCid(sid);
    console.log('RESPONSE: '+JSON.stringify(response));
    // const score = get(response, 'data.answerExam.score', 0);
    
    console.log('pass: '+examId+' - '+get(response, 'data.answerExam.score', 0)+' : '+ sid);

    handleClickConfirm(sid, uid, '2', examId);

    // return get(response, 'data.answerExam.score', 0);
  }
  const handleSubmit = async ({ userQA = {} }, tempoarary) => {
    const answers = Object.entries(userQA)
      .map(([qasId, choiceId]) =>
        ({ qasId: Number(qasId), choiceId: Number(choiceId) }));
    
    const response = await answerExam({ variables: { examId, answers, tempoarary }});

    return get(response, 'data.answerExam.score', 0);
  }

  return (
    <Loading loading={loading} error={error} refetch={beginExam}>
      <Exam
        examKey={`${EXAM_TYPE.actual}_${examId}`}
        secondsPerQuestion={secondsPerQuestion}
        inCourseItemMode={true}
        questions={questions}
        examName={examName}
        liveChecking={examCheatsEnabled ? performChecking : undefined}
        onSubmit={handleSubmit}
        onSubmity={handley}
      />
    </Loading>
  )
}

export default memo(ExamPage);
