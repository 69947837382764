import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Grid, Typography, DialogTitle as MuiDialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Courses from 'components/common/Courses';

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: 32,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  competence: {
    marginLeft: 15
  },
  kup: {
    marginLeft: 15*2,
    listStyleType: 'disc'
  },
  kupCat: {
    marginLeft: 15,
    listStyleType: 'circle'
  }
}));

const ServicesOfferedDialog = ({
  isOpen,
  closeModal,
  course,
  ...rest
}) => {
  const classes = useStyles();
  // const fetchPolicy = courses && courses.length > 0
  //   ? 'cache-first'
  //   : 'cache-and-network';

  const DialogTitle = ((props) => {
    const { children, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  return (
    <Dialog
      open={isOpen}
      maxWidth='lg'
      {...rest}
      onClose={closeModal}
    >
      <DialogTitle id="customized-dialog-title" onClose={closeModal}>
        { course?.servicesOfferedLabel || course?.name }
      </DialogTitle>
      
      <Grid container item classes={{ root: classes.grid }}>
        {
          !!course && course.categories.map((category, index) => (
            <CategoryItem category={category} key={"category-"+index}/>
          ))
        }
        {/*<Courses
          courses={courses}
          queryOptions={{ fetchPolicy }}
          hideHeader={true}
          startExpanded={true}
          disableExpandCollapse={true}
          hideActionButtons={true}
          hideTakeExamButtons={true}
          showCategoryItems={true}
          showExamButtonAsDisabledIfNotAccepted={true}
        />*/}
      </Grid>
    </Dialog>
  )
}

ServicesOfferedDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  courses: PropTypes.arrayOf(PropTypes.object),
}

const CategoryItem = ({category}) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <b>{ category.name }</b>
      </Grid>
      {
        category.items.map((item, index) => (
          <div key={"category-item-"+index}>
            <Grid item xs={12} className={classes.competence}>
              { item.name }
            </Grid>
            {
              item.kupsWithCategory.length > 0 && (
                <ul className={classes.kup}>
                  {
                    item.kupsWithCategory.map((kup, i) => (
                      <li key={"category-item-kup-"+i}>
                        { kup.text }
                        {
                          kup.categories.length > 0 && (
                            <ul className={classes.kupCat}>
                              {
                                kup.categories.map((kupCat, ii) => <li key={"category-item-kup-category-"+ii}>{kupCat}</li>)
                              }
                            </ul>
                          )
                        }
                      </li>
                    ))
                  }
                </ul>
              )
            }
          </div>
        ))
      }
    </Grid>
  )
}

export default memo(ServicesOfferedDialog)
