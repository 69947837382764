import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import PrivateRoute from './PrivateRoute';
import { checkIfAdmin } from 'reduxConfig/selectors';

const AdminRoute = props => {
  const isAdmin = useSelector(checkIfAdmin);

  return isAdmin
    ? <PrivateRoute {...props} />
    : <Redirect to='/' />
}

export default AdminRoute;
