import { gql } from '@apollo/client';

const HELLO = gql`{ hello }`;

const RANKS = gql`
  query {
    ranks {
      id
      name
    }
  }
`

const GET_USER_TAKING_EXAM_FOR_COURSE_ID = gql`
  query user {
    currentUser {
      takingExamForCourseId
    }
  }
`

const _EXAM_FRAGMENT = gql`
  fragment NonCalculatedExamFields on Exam {
    id
    name
  }
`

const _COURSE_CATEGORY_FRAGMENT = gql`
  fragment NonCalculatedCourseCategoryFields on CourseCategory {
    id
    name
    items {
      id
      name
      kupsWithCategory
    }
  }
`

const _COURSES_FRAGMENT = gql`
  ${_COURSE_CATEGORY_FRAGMENT}
  ${_EXAM_FRAGMENT}

  fragment CoursesFields on Course {
    id
    code
    name
    servicesOfferedLabel
    description
    price
    maxExamAttempts
    categories {
      ...NonCalculatedCourseCategoryFields
      hasExams
      exams {
        ...NonCalculatedExamFields
      }
    }
  }
`

const COURSES = gql`
  ${_COURSES_FRAGMENT}

  query {
    courses {
      ...CoursesFields
    }
  }
`

const COURSES_WITH_USER_COURSE = gql`
  ${_COURSES_FRAGMENT}

  query {
    courses {
      ...CoursesFields
      userCourse {
        status
        approverDenierMessage
      }
    }
  }
`

const COURSES_TRIAL_EXAM_BUTTON = gql`
  ${_EXAM_FRAGMENT}

  query {
    courses {
      name
      categories {
        name
        exams {
          ...NonCalculatedExamFields
        }
      }
      maxExamAttempts
    }
  }
`

// TODO "canOnlyTakeTrialExam" flag no longer needed here
const EXAM_LIST_PAGE = gql`
  ${_COURSE_CATEGORY_FRAGMENT}
  ${_EXAM_FRAGMENT}

  query courseCategoriesWithExamFlag($courseId: Int, $userId: Int, $isForResult: Boolean) {
    courseCategoriesWithExamFlag(courseId: $courseId, userId: $userId, isForResult: $isForResult) {
      hash
      dateTaken
      courseCategories {
        id
        ...NonCalculatedCourseCategoryFields
        exams {
          ...NonCalculatedExamFields
          attempts
          score
          numberOfQuestions:maxQuestionsToUse
        }
      }
      maxExamAttempts
      canOnlyTakeTrialExam
      functionsWithCompetence
      setting {
        passingCourseRate
        passingExamRate
      },
      course {
        code
        name
        description
      }
    }
  }
`

const COURSE_APPROVALS = gql`
  query courseApprovals($statuses: [String]) {
    courseApprovals(statuses: $statuses) {
      id
      receiptPath
      receiptComment
      receiptAmountpaid
      status
      course {
        code
        name
      }
      user {
        id
        username
      }
      appliedOn
      paymentPostedOn
      approvedDeniedOn
      approverDenierMessage
      schedule
    }
  }
`

// TODO Probably can use the user fragment from mutations here
const USERS = gql`
  query users {
    users {
      id
      username
      email
      firstName
      middleName
      lastName
      graduatedFrom
      graduatedOn
      birthday
      address
      phoneNumber
      company
      referredBy
      rank {
        name
      }
      isAdmin
      canTakeExams
      canAlwaysAccessCourse
      showCoursesInExamMode
      examCheatsEnabled
      createdOn
      courses {
        status
        appliedOn
        course {
          id
          name
          code
        }
        user {
          username
        }
      }
    }
  }
`

const BEGIN_EXAM_TRIAL = gql`
  query beginExamTrial($examId: Int!) {
    beginExamTrial(examId: $examId) {
      name
      questions {
        id
        text
        choices {
          id
          text
          isCorrect
        }
      }
    }
  }
`

const BEGIN_EXAM_REVIEW = gql`
  query beginExamReview($courseItemId: Int!) {
    beginExamReview(courseItemId: $courseItemId) {
      name
      questions {
        id
        text
        choices {
          id
          text
          isCorrect
        }
      }
    }
  }
`

const ADMIN_DASHBOARD = gql`
  query adminDashboard($startDate: String, $endDate: String) {
    adminDashboard(startDate: $startDate, endDate: $endDate) {
      registeredUsers,
      registeredUsersPaid,
      registeredUsersToday,
      examineesPassed,
      examineesFailed,
      examineesNoResponse,
      earningsMonth,
      earningsYear,
      earningsPeriod,
      modulesCompleted,
      pendingRequests,
      siteVisitors,
    }
  }
`

const EXAM_SCHEDULES = gql`
  query toggleAddSched {
    toggleAddSched {
      approve
    }
  }
`

const GET_SCHEDULE = gql`
  query getUserSchedule {
    getUserSchedule{
      id
      userId
      courseId
      schedule
    }
  }
`;

const GET_EXAM_SCHEDULE = gql `
  query getExamSchedule {
    getExamSchedule{
      id
      exameDate
      examTime
      examTotal
    }
  }
`;

const GET_USER_SCHEDULE = gql`
  query getcurrentuserschedule($userId: Int!) {
    getcurrentuserschedule(userId: $userId) {
      schedule
    }
  }
`

const GET_COMP_SCORE = gql`
  query getcompetencescore($uid: Int){
    getcompetencescore(uid: $uid){
      courseItemID
      score
      examId
    }
  }
`

const EXAM_RESULT = gql`
  query getExamResult ($token: String) {
    getExamResult(token: $token) {
      course
      examinee
      examDate
      rating
    }
  }
`

const SETTING = gql`
  query settings {
    settings {
      numberOfExams
      passingCourseRate
      passingExamRate
      createdOn
      updatedOn
    }
  }
`

const ALL_EXAMS = gql`
  query allExams {
    allExams {
      id
      maxQuestionsToUse
      maxTrialQuestionsToUse
      visible
    }
  }
`

const COURSES_WITH_FUNCTION_AND_COMPETENCES = gql`
  query {
    courses {
      id
      name
      servicesOfferedLabel
      categories {
        name
        items {
          name
          kupsWithCategory
        }
      }
    }
  }
`

export {
  HELLO,
  RANKS,
  EXAM_RESULT,
  ALL_EXAMS,
  GET_USER_TAKING_EXAM_FOR_COURSE_ID,
  COURSES,
  COURSES_WITH_USER_COURSE,
  COURSES_TRIAL_EXAM_BUTTON,
  EXAM_LIST_PAGE,
  COURSE_APPROVALS,
  EXAM_SCHEDULES,
  USERS,
  BEGIN_EXAM_TRIAL,
  BEGIN_EXAM_REVIEW,
  ADMIN_DASHBOARD,
  GET_SCHEDULE,
  GET_EXAM_SCHEDULE,
  GET_USER_SCHEDULE,
  GET_COMP_SCORE,
  SETTING,
  COURSES_WITH_FUNCTION_AND_COMPETENCES,
};
