import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Route from './_Route';
import { getAuthToken } from 'reduxConfig/selectors';

const PrivateRoute = props => {
  const token = useSelector(getAuthToken);
  
  const additionalProps = [
    'header' in props ? null : 'noHeader',
    'noSidebar' in props ? null : 'sidebar',
  ].filter(x => x).reduce((acc, x) => ({...acc, [x]: true}), {});

  return token
    ? <Route {...additionalProps} {...props} />
    : <Redirect to='/login' />
}

export default PrivateRoute;
