import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { logout } from 'reduxConfig/actions/currentUser';

// TODO Fix this error:
// Warning: Cannot update a component (`App`) while rendering a different component (`LogoutPage`).
// To locate the bad setState() call inside `LogoutPage`, follow the stack trace as described in https://fb.me/setstate-in-render
const LogoutPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  dispatch(logout());
  history.push('/courses');

  return null;
}

export default LogoutPage;
