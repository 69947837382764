const moment = require('moment');
const Yup = require('yup');
const { get } = require('lodash');
const { MAX_FILE_SIZE } = require('./constants');

const LEGAL_AGE = moment().subtract(18, 'years').startOf('day');
const TODAY = moment().startOf('day');
const SUPPORTED_IMAGE_EXTENSIONS = [
  'jpg',
  'jpeg',
  'gif',
  'png',
  'tif',
  'tiff',
  'bmp',
  'webp',
]
const SUPPORTED_IMAGE_MIMES = SUPPORTED_IMAGE_EXTENSIONS.map(x => `image/${x}`);

const TYPE_ERROR = {
  date: '${path} must be a valid date',
}

const GENERIC_STRING_VALIDATION = Yup.string().nullable();
const GENERIC_REQUIRED_STRING_VALIDATION = Yup.string().required('${path} is required').nullable().min(2);

// https://github.com/formium/formik/issues/926:
const createFileSizeValidation = ({
  label = '${path}', // probably a more "yup" way to do this
  maxSize = MAX_FILE_SIZE,
}) => Yup.mixed()
  .required(`${label} is required`)
  .test('fileSize', "File is too large", async valuePromise => {
    const value = await Promise.resolve(valuePromise);
      
    return get(value, 'size', 0) <= maxSize;
  })
  .test('fileType', "Unsupported file format", async valuePromise => {
    const value = await Promise.resolve(valuePromise);

    const mimetype = get(value, 'mimetype', get(value, 'type', ''));
    const ext = get(value, 'filename', get(value, 'name', '')).split('.').pop();

    const isValidMime = SUPPORTED_IMAGE_MIMES.includes(mimetype);
    const isValidExt = SUPPORTED_IMAGE_EXTENSIONS.includes(ext);
    const isValid = isValidMime && isValidExt;

    return isValid;
  })

let _USER_VALIDATION = {
  username: Yup.string()
    .label('Username')
    .min(2)
    .max(50),
  email: Yup.string()
    .label('Email')
    .email(),
  password: Yup.string().label('Password'),
  password2: Yup.string()
    .label('Confirm password')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
  firstName: GENERIC_REQUIRED_STRING_VALIDATION.label('First name'),
  middleName: GENERIC_REQUIRED_STRING_VALIDATION.label('Middle name'),
  lastName: GENERIC_REQUIRED_STRING_VALIDATION.label('Last name'),
  rank: Yup.number().label('Rank'),
  graduatedFrom: GENERIC_REQUIRED_STRING_VALIDATION.label('Graduated from'),
  graduatedOn: Yup.date().typeError(TYPE_ERROR.date).max(TODAY, 'Date must be before today').label('Graduated on'),
  birthday: Yup.date().typeError(TYPE_ERROR.date).max(LEGAL_AGE, 'To register you must be 18 or older').label('Birthday'),
  address: GENERIC_REQUIRED_STRING_VALIDATION.label('Address'),
  phoneNumber: Yup.string().min(6).label('Phone number'),
  company: Yup.string().label('Company'),
  referredBy: GENERIC_STRING_VALIDATION.label('Referred by'),
}

_USER_VALIDATION.newPassword = _USER_VALIDATION.password;
_USER_VALIDATION.newPassword2 = Yup.string()
  .label('Confirm password')
  .oneOf([Yup.ref('newPassword')], 'Passwords must match');

const _sharedProfileFields = {
  firstName: _USER_VALIDATION.firstName,
  middleName: _USER_VALIDATION.middleName,
  lastName: _USER_VALIDATION.lastName,
  email: _USER_VALIDATION.email,
  rank: _USER_VALIDATION.rank,
  graduatedFrom: _USER_VALIDATION.graduatedFrom,
  graduatedOn: _USER_VALIDATION.graduatedOn,
  birthday: _USER_VALIDATION.birthday,
  address: _USER_VALIDATION.address,
  phoneNumber: _USER_VALIDATION.phoneNumber,
  company: _USER_VALIDATION.company,
  referredBy: _USER_VALIDATION.referredBy,
}

const signup = Yup.object().shape({
  ..._sharedProfileFields,
  username: _USER_VALIDATION.username.required(),
  email: _USER_VALIDATION.email.required(),
  password: _USER_VALIDATION.password.required(),
  password2: _USER_VALIDATION.password2.required(),
  rank: _USER_VALIDATION.rank.required(),
  graduatedOn: _USER_VALIDATION.graduatedOn.required(),
  birthday: _USER_VALIDATION.birthday.required(),
  phoneNumber: _USER_VALIDATION.phoneNumber.required(),
});

const updateProfile = Yup.object().shape({
  ..._sharedProfileFields,
  currentPassword: _USER_VALIDATION.password.required(),
  newPassword: _USER_VALIDATION.newPassword,
  newPassword2: _USER_VALIDATION.newPassword2,
})

const login = Yup.object().shape({
  username: _USER_VALIDATION.username.required(),
  password: _USER_VALIDATION.password.required(),
});

const payCourse = Yup.object().shape({
  receiptImage: createFileSizeValidation({ label: 'Receipt' }),
  receiptComment: Yup.string().label('Comment'),
})

const approveDenyCourseApplication = Yup.object().shape({
  // approve: Yup.boolean().required(),
  approverDenierMessage: Yup.mixed().when('approve', {
    is: false,
    then: Yup.string().label('Message').required(),
  }),
})

module.exports = {
  signup,
  updateProfile,
  login,
  payCourse,
  approveDenyCourseApplication,
}
