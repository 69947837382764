import React, { memo } from 'react';
import { COURSES_WITH_USER_COURSE } from 'constants/queries';
import Courses from 'components/common/Courses';

const CoursesTakeExamPage = () => {  
  return (
    <Courses
      query={COURSES_WITH_USER_COURSE}
      hideActionButtons={true}
      showExamButtonAsDisabledIfNotAccepted={true}
    />
  )
}

export default memo(CoursesTakeExamPage);
