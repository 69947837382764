import { AUTH_TOKEN } from 'constants/etc';
import { SET_USER, UPDATE_USER, CLEAR_USER } from '../constants';

const DEFAULT_STATE = {
  isAdmin: false,
  canTakeExams: false,
  canAlwaysAccessCourse: false,
  showCoursesInExamMode: false,
  examCheatsEnabled: false,
};

// Used by ApolloLink. TODO See if Apollo can just access Redux,
// have a single source of truth, dedupe, etc, etc.
const setOrRemoveAuthToken = (newState = {}) => {
  if (newState.token) {
    localStorage.setItem(AUTH_TOKEN, newState.token);
  } else {
    localStorage.removeItem(AUTH_TOKEN);
  }

  return newState;
}

export default (state = DEFAULT_STATE, { type, payload }) => {
  switch (type) {
    case SET_USER: {
      return setOrRemoveAuthToken({
        ...DEFAULT_STATE,
        ...payload,
      });
    }

    case UPDATE_USER: {
      return setOrRemoveAuthToken({
        ...DEFAULT_STATE,
        ...state,
        ...payload,
      });
    }

    case CLEAR_USER: {
      return setOrRemoveAuthToken(DEFAULT_STATE);
    }

    default:
      return state
  }
}
