import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { get, groupBy, noop, orderBy } from 'lodash';
import memoize from 'memoize-one';
import ExamContent from './ExamContent';

import { useSnackbar } from 'notistack';
import { saveAnswers, clearAnswers } from 'reduxConfig/actions/savedExamAnswers';
import { getSavedExamAnswers, getCurrentUserID } from 'reduxConfig/selectors';
import { getResponseError } from 'utils';

const getOrderedQuestions = memoize(questions => orderBy(questions, x => get(x, 'courseItem.id')));
const getGroupedQuestions = memoize(orderedQuestions => groupBy(orderedQuestions, x => get(x, 'courseItem.id')));

const Exam = ({
  questions,
  inCourseItemMode,
  itemsPerPage,
  examKey,
  liveChecking,
  onSubmit,
  onSubmity,
  onError,
  onFinally,
  secondsPerQuestion,
  ...rest
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const uid = useSelector(getCurrentUserID);
  const expensiveFilter = useSelector(getSavedExamAnswers);
  const savedExamAnswers = expensiveFilter(examKey) || {};

  // Reminder: this is ONE-indexed:
  const [currentPage, setCurrentPage] = useState(1);
  const [otherExamContentVariables, setOtherExamContentVariables] = useState(); 
  const isReviewPage = window.location.pathname.indexOf('review') == 1
  const [submitting, setSubmitting] = useState(false);
  const [correctAnswerCount, setCorrectAnswerCount] = useState(null);

  const [userQA, setAnswers] = useState(savedExamAnswers);
  const [liveCheckingResults, setLiveCheckingResults] = useState({});
  const [sid, setSID] = useState();
  let subtitle_id;

  const setAnswer = (k, v) => {
    const newAnswers = { ...userQA, [k]: v };

    // NOTE We are saving to two separate places here: the state here and Redux.
    // The Redux one will be used for loading saved answers (if a key is set),
    // but otherwise the one in state will be used:
    setAnswers(newAnswers);
    if (examKey) {
      dispatch(saveAnswers({ examKey, currentPage, answers: newAnswers }));
    }

    if (liveChecking) {
      setLiveCheckingResults(liveChecking({ questions, currentPage, userQA: newAnswers }));
    }
    console.log('page: '+currentPage);
    // console.log('save ans: '+JSON.stringify(savedExamAnswers)+' '+uid+' '+correctAnswerCount);
    // submity();
  }

  const submity = async () => {
    const cra = await onSubmity({ questions, userQA },sid, true);
    setCorrectAnswerCount(cra);
    // console.log('COUNT: '+liveChecking);
    console.log('save ans: '+JSON.stringify(savedExamAnswers)+' '+uid+' '+sid);
    setSubmitting(false);
  }

  const submit = async () => {
    setSubmitting(true);

    try {
      const correctAnswerCount = await onSubmit({ questions, userQA }, false);
      setCorrectAnswerCount(correctAnswerCount);
      enqueueSnackbar('Exam submitted successfully', { variant: 'success' });

      console.log('Correct: '+correctAnswerCount);

      if (examKey) {
        dispatch(clearAnswers(examKey));
      }

    } catch(e) {
      if (onError) {
        onError(e);
      } else {
        enqueueSnackbar(`Error submitting exam: ${getResponseError(e)}`, { variant: 'error' });
      }
    } finally {
      onFinally();
      setSubmitting(false);
    }
  }

  useEffect(() => {
    let lastPage, firstQuestionIndex, questionsForPage, subtitle = '';
    
    // TODO Implementation could be faster, freezes briefly
    //
    // Addendum: Making ExamContent just return page numbers, and the lag is gone.
    // So most likely the cause of the lag is rerendering the questions themselves.
    if (inCourseItemMode) {
      // Should already be ordered from server, but might not be in cases of backwards compt:
      const orderedQuestions = getOrderedQuestions(questions);
      const groupedQuestions = getGroupedQuestions(questions);
      const questionsPerCompetence = window._.flatten(Object.values(groupedQuestions))

      const courseItemIds = Object.keys(groupedQuestions);
      // const currentCourseItemId = courseItemIds[currentPage - 1];

      // console.log()
      
      // lastPage = courseItemIds.length;
      lastPage = questionsPerCompetence.length
      // questionsForPage = groupedQuestions[currentCourseItemId];
      // questionsForPage = [questionsPerCompetence[currentPage-1]];
      questionsForPage = questionsPerCompetence.slice(currentPage-1, currentPage);
      const firstQuestion = get(questionsForPage, '[0]');
      // firstQuestionIndex = orderedQuestions.findIndex(x => get(x, 'id') === firstQuestion.id) || 0;
      firstQuestionIndex = [0]
      subtitle = get(firstQuestion, 'courseItem.name');
      // subtitle_id = get(firstQuestion, 'courseItem.id');
      // console.log('Item ID: '+subtitle_id);
      setSID(get(firstQuestion, 'courseItem.id'));

    } else {
      lastPage = Math.ceil(questions.length / itemsPerPage);
      firstQuestionIndex = 0 + ((currentPage - 1) * itemsPerPage);
      const lastQuestionIndex = firstQuestionIndex + itemsPerPage;
      questionsForPage = questions.slice(firstQuestionIndex, lastQuestionIndex);
    }
    // console.log('Item ID: '+subtitle_id);
    // console.log('question: '+ JSON.stringify(questionsForPage));

    setOtherExamContentVariables({
      lastPage,
      questions: questionsForPage,
      firstQuestionNumber: firstQuestionIndex + 1,
      subtitle,
    });

    return noop;
  }, [questions, currentPage, inCourseItemMode, itemsPerPage]);

  return (
    <ExamContent
      {...rest}
      secondsPerQuestion={secondsPerQuestion}
      pagination={isReviewPage ?'page' :'next'}
      initialized={!!otherExamContentVariables}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      setAnswer={setAnswer}
      submit={submit}
      submity={submity}
      submitting={submitting}
      correctAnswerCount={correctAnswerCount}
      userQA={userQA}
      liveCheckingResults={liveCheckingResults}
      highlightIfCorrectWrong={!!liveChecking}
      totalQuestionsCount={questions.length}
      {...otherExamContentVariables}
    />
  )
}

Exam.propTypes = {
  questions: PropTypes.array.isRequired,
  inCourseItemMode: PropTypes.bool,
  itemsPerPage: PropTypes.number, // only used if inCourseItemMode is false
  examKey: PropTypes.string,
  liveChecking: PropTypes.func,
  onSubmit: PropTypes.func,
  onSubmity: PropTypes.func,
  onError: PropTypes.func,
  onFinally: PropTypes.func,
}

Exam.defaultProps = {
  questions: [],
  inCourseItemMode: false,
  itemsPerPage: 1,
  onSubmit: noop,
  onSubmity: noop,
  onFinally: noop,
}

export default memo(Exam);
