import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { COURSE_APPROVALS as QUERY } from 'constants/queries';
import { APPROVE_DENY_COURSE_APPLICATION as MUTATION } from 'constants/mutations';
import { useMutation } from 'utils';

const ApproveButton = ({
  id,
  ...rest
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [mutate] = useMutation(MUTATION, {
    enqueueSnackbar,
    successMessage: 'Application approved successfully',
    errorMessage: 'Error approving application',
    update(store, { data: { approveDenyCourseApplication: { id }}}) {
      const { courseApprovals } = store.readQuery({ query: QUERY });

      store.writeQuery({ query: QUERY, data: { courseApprovals:
        courseApprovals.filter(x => x.id !== id)
      }});
    },
  });

  const onClick = () => {
    mutate({ variables: { id, approve: true }});
  }

  return (
    <Button
      variant='contained'
      color='primary'
      onClick={onClick}
      {...rest}
    >
      Approve
    </Button>
  )
}

ApproveButton.propTypes = {
  id: PropTypes.number.isRequired,
}

export default memo(ApproveButton);
