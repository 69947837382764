import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';

// import { EXAMINEE_RESULT } from '../../../constants/queries'
// import { useLazyQuery } from '@apollo/client'


function ResultDialog ({
  examinee,
  open,
  onClose
}) {
  const handleViewResult = (courseId) => {
    // console.log('handleViewResult', courseId)
    window.open('/exam-results/'+courseId+'/'+examinee.id, '_blank', 
      Object.entries({
        toolbar: 0,
        location: 0,
        menubar: 0,
        height: Math.min(Math.max(window.screen.height*.5, 1280), window.screen.height*.75),
        width: Math.min(Math.max(window.screen.width*.5, 720), window.screen.width*.75),
      }).map(([k,v]) => `${k}=${v}`).join(',')
    )
  }
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Exam Results</DialogTitle>
      <DialogContent>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Course</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {window._.get(examinee, 'courses', []).map((row) => (
              <TableRow key={'course-'+row.name}>
                <TableCell component="th" scope="row">
                  {row.course.code}
                </TableCell>
                <TableCell>
                  {row.course.name}
                </TableCell>
                <TableCell>
                  <Tooltip title="See result">
                    <IconButton color="primary" onClick={() => handleViewResult(Number(row.course.id))}>
                      <PlaylistAddCheckIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {/*{
          (() => {
            if(DialogMachine[0].matches('fetch')) {
              return (
                <Typography>Please wait. . .</Typography>
              )
            }
            if(DialogMachine[0].matches('failure')) {
              return (
                <Typography>Failure!!!</Typography>
              )
            }
            return (
              <Typography>Results</Typography>
            )
          })()
        }*/}
        {/*<DialogContentText>
          To subscribe to this website, please enter your email address here. We will send updates
          occasionally.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Email Address"
          type="email"
          fullWidth
        />*/}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
ResultDialog.propTypes = {
  examinee: PropTypes.number,
  open: PropTypes.bool,
  onClose: PropTypes.func
}
ResultDialog.defaultProps = {
  open: false,
  onClose: () => {}
}

export default ResultDialog