import React, { memo } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { yellow } from '@material-ui/core/colors';
import WarningIcon from '@material-ui/icons/Warning';
import B from 'components/common/B';

const useStyles = makeStyles((theme) => ({
  icon: {
    width: '4vh',
    height: 'auto',
    color: yellow[500],
  },
}));

const Title = () => {
  const classes = useStyles();

  return (
    <Grid container alignItems='center' justify='center' spacing={2}>
      <Grid item>
        <WarningIcon className={classes.icon} />
      </Grid>
  
      <Grid item>
        <B variant='h4'>IMPORTANT MESSAGE</B>
      </Grid>
    </Grid>
  )
}

export default memo(Title);
