import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';

import ExamMode from 'components/common/ExamMode';
import Button from './Button';
import ServicesOfferedButton from './ServicesOfferedButton';
import TrialExamButton from './TrialExamButton';
// import Logo from './Logo';
import { getAuthToken } from 'reduxConfig/selectors';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 8,
    paddingBottom: 8,
    minHeight: '8vh',
  },
}));

const Header = ({ isHomePage }) => {
  const classes = useStyles();
  const token = useSelector(getAuthToken);

  return (
    <Grid container item classes={{ container: classes.container }}>
      <Grid container item xs={1} />

      {/*<Grid container item xs={3} justify='center'>
        <Logo />
      </Grid>*/}

      <Grid container item xs={4} justify='center'>
        <Button url="/">Home</Button>
        <Button href="#about">About</Button>
        <ServicesOfferedButton isHomePage={isHomePage} />
        <ExamMode.Hide><TrialExamButton /></ExamMode.Hide>
      </Grid>

      <Grid container item xs={4} />

      <Grid container item xs={2} justify='center'>
        {
          token
            ? <Button url='/logout'>Logout</Button>
            : (<>
              <Button url='/register'>Sign Up</Button>
              <Button url='/login'>Login</Button>
            </>)
        }
      </Grid>

      <Grid container item xs={1} />
    </Grid>
  )
}

export default memo(withRouter(Header))
