import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context'
import { createUploadLink } from 'apollo-upload-client';
import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { get, noop } from 'lodash';

import App from 'components/App';
import { Provider as GlobalDialogProvider } from 'globalDialog';
import { AUTH_TOKEN } from 'constants/etc';
import * as serviceWorker from './serviceWorker';
import { getApiUrl } from 'utils';
import configureStore from './reduxConfig/configureStore';
import './assets/main.css';

import { GlobalStateProvider } from './global.state'

const { store, persistor } = configureStore();

const httpLink = createUploadLink({
  uri: getApiUrl(),
  headers: { "keep-alive": "true" },
});

// TODO Replace this implementation
// (https://www.rdegges.com/2018/please-stop-using-local-storage/):
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(AUTH_TOKEN);

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const QUERY_DEFAULT_OPTIONS = {
  fetchPolicy: 'cache-and-network',
  errorPolicy: 'all',
}

const client = new ApolloClient({
  link: ApolloLink.from([authLink, httpLink]),
  cache: new InMemoryCache(),
});

// https://github.com/apollographql/apollo-client/issues/2555#issuecomment-648280766:
client.defaultOptions = {
  // https://github.com/apollographql/apollo-client/issues/2555#issuecomment-490866804:
  watchQuery: QUERY_DEFAULT_OPTIONS,
  query: QUERY_DEFAULT_OPTIONS,
}

const notistackRef = React.createRef();
const onClickDismiss = key => () => { 
  get(notistackRef, 'current.closeSnackbar', noop)(key);
}

ReactDOM.render(
  <BrowserRouter>
    <ReduxProvider store={store}>
      <ApolloProvider client={client}>
        <PersistGate loading={null} persistor={persistor}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <SnackbarProvider
              ref={notistackRef}
              maxSnack={3}
              variant='info'
              action={key => (
                <Button onClick={onClickDismiss(key)}>
                  Ok
                </Button>
              )}
            >
              <GlobalStateProvider>
                <GlobalDialogProvider>
                  <App />
                </GlobalDialogProvider>
              </GlobalStateProvider>
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </PersistGate>
      </ApolloProvider>
    </ReduxProvider>
  </BrowserRouter>,
  document.getElementById('root')
)

serviceWorker.unregister();
