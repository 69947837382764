import * as yup from 'yup'
import { useFormik } from 'formik';

import React, { memo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, TextField, FormControl, FormHelperText } from '@material-ui/core';
import { KeyboardDatePicker, TimePicker } from '@material-ui/pickers'
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useSnackbar } from 'notistack';
import moment from "moment";
import ExamSchedules from './ExamSchedulesPage'

import { login as LOGIN_VALIDATION } from '@nereus/shared/validation';
import { LOGIN as LOGIN_MUTATION } from 'constants/mutations';
import { login as loginAction } from 'reduxConfig/actions/currentUser';
import { useMutation } from 'utils';
// import Field from './_Field';
// import Form from './_Form';
import { ADD_SCHEDULE } from 'constants/mutations';

const useStyles = makeStyles((theme) => ({
  image: {
    backgroundImage: "url('/images/main_background.png')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    paddingLeft: 40,
    paddingRight: 40,
    minHeight: '100vh',
  },
}));

const AddSchedModal = ({closeDialog}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const validation = useFormik({
    initialValues: {
      examDate: moment().format('MM-DD-YYYY'),
      time: moment().format('h:mm a'),
      participants: 0
    },
    validationSchema: yup.object({
      examDate: yup.string()
        .required('The date field is required.')
        .test(
          'is-morethan-date',
          'The date field is should be at future.',
          (value, context) => {
            const date = moment(value, 'MM-DD-YYYY')
            if(date.isValid()) {
              return date.isSameOrAfter(moment(moment().format('MM-DD-YYYY')))
            }
            return false;
          }
        ),
      time: yup.string()
        .required('The time field is required.'),
      participants: yup.number()
        .integer('The participants field is should be integer.')
        .max(999, 'The participants field max values is 999.')
    }),
    onSubmit(values) {
      mutation({
        variables: {
          setdate: values.examDate,
          settime: values.time,
          participants: values.participants,
        },
      });
    }
  })

  const [selectedDate, setDate] = useState(moment());
  const [inputValue, setInputValue] = useState(moment().format("MM-DD-YYYY"));

  const onDateChange = (date, value) => {
    validation.setFieldValue('examDate', value)
  };
  const [selectedTime, setTime] = useState(moment());
  const [inputValueTime, setInputValueTime] = useState(moment().format("h:m a"));

  const onTimeChange = (time, value) => {
    validation.setFieldValue('time', time.format('h:mm a'))
  };
  const [selectedPart, setPart] = useState(moment());
  const [ inputParticipants, setInputParticipants ] = useState();
  const onPartChange = (value) => {
    validation.setFieldValue('participants', Number(value) || 0)
  };

  const handleClickConfirm = (evt) => {
    evt.preventDefault();
    validation.handleSubmit()
  }

  const [mutation, { loading: isLoading }, mutationResults] = useMutation(
    ADD_SCHEDULE,
    {
      onCompleted: (data) => {
        closeDialog();
        console.log(data);
        window.location='/admin/exam-schedules';
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  return (
    <Grid>
      <FormControl>
        <Grid container item spacing={2}>
          <Grid container item>
            <KeyboardDatePicker
              minDate={new Date()}
              variant='outlined'
              fullWidth
              format="MM-DD-YYYY"
              name='date'
              label='Date'
              value={validation.values.examDate}
              inputValue={validation.values.examDate}
              onChange={onDateChange}
            />
            {
              Boolean(validation.errors.examDate) && (
                <FormHelperText error>{validation.errors.examDate}</FormHelperText>
              )
            }
          </Grid>

          <Grid container item>
            <TimePicker
              label='Time'
              name='time'
              fullWidth
              format="h:mm a"
              onChange={onTimeChange}
              value={moment('01-01-2000 ' +validation.values.time, 'MM-DD-YYYY h:mm a')}
            />
            {
              Boolean(validation.errors.time) && (
                <FormHelperText error>{validation.errors.time}</FormHelperText>
              )
            }
          </Grid>

          <Grid container item>
            <TextField
              label='No. of Participants'
              name='participants'
              fullWidth
              type="number"
              value={validation.values.participants}
              InputProps={{ inputProps: { min: 0 } }}
              onInput={event => onPartChange(event.target.value)}
              error={validation.errors.participants}
              helperText={Boolean(validation.errors.participants) && validation.errors.participants}
            />
          </Grid>
        </Grid>
      </FormControl>
      <Grid className='mt-5 float-right'>
        <Button
          variant='contained'
          onClick={closeDialog}
          autoFocus
        >
          Cancel
        </Button>
        &nbsp;
        <Button
          variant='contained'
          className='bg-green-400 text-white'
          onClick={handleClickConfirm}
        >
          Confirm
        </Button>
      </Grid>
    </Grid>
  )
}

export default memo(AddSchedModal);
