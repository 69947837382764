import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { get, identity, isEmpty, pick, pickBy } from 'lodash';
import { useSnackbar } from 'notistack';

import WelcomeHeader from 'components/common/WelcomeHeader';
import Loading from 'components/common/Loading';
import NoPrint from 'components/common/NoPrint';
import { ADMIN_DASHBOARD as QUERY } from 'constants/queries';
import { setSiteVisitors } from 'reduxConfig/actions/misc';
import { useQuery, displayMoney } from 'utils';
import Filters from './Filters';
import SquareCard from './SquareCard';
import LongCard from './LongCard';
import PieChart from './PieChart';
import SiteVisitors from './SiteVisitors';

const DEFAULT_DATA = {
  registeredUsers: 0,
  registeredUsersPaid: 0,
  registeredUsersToday: 0,
  examineesPassed: 0,
  examineesFailed: 0,
  examineesNoResponse: 0,
  earningsMonth: 0,
  earningsYear: 0,
  earningsPeriod: null,
  modulesCompleted: 0,
  pendingRequests: 0,
}
const DEFAULT_DATA_KEYS = Object.keys(DEFAULT_DATA);

export const PIE_CHART_CONTAINER_MD = 7;
export const LONG_CARDS_CONTAINER_MD = 5;

const AdminDashboard = ({
  CustomHeader,
  squareCardProps,
  pieChartContainerProps,
  longCardsContainerProps,
  longCardProps,
}) => { 
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { data: rawData, refetch, variables, LoadingProps } = useQuery(QUERY, {
    enqueueSnackbar,
    errorMessage: 'Error fetching dashboard data',
    onCompleted: data => {
      const count = Math.max(get(data, 'adminDashboard.siteVisitors'), 0);
      const earn = get(data, 'adminDashboard');
      console.log('nara: '+JSON.stringify(earn));

      dispatch(setSiteVisitors(count));
    },
  });

  const isFiltering = !isEmpty(pickBy(variables, identity));

  const data = pick({
    ...DEFAULT_DATA,
    ...get(rawData, 'adminDashboard', {}),
  }, DEFAULT_DATA_KEYS);
  console.log('DATA: '+JSON.stringify(data));
  console.log('DATA RAW'+JSON.stringify(rawData));

  const earningsPeriodText = isFiltering
    ? 'Earnings for date range'
    : 'Earnings total';

  const squareCardBreakpointMd = Math.floor(12 / (3 + !isFiltering));

  return (
    <Grid container item spacing={2} justify='center'>
      { CustomHeader && <CustomHeader isFiltering={isFiltering} {...variables} /> }

      <NoPrint>
        <Filters refetch={refetch} />
      </NoPrint>

      <Loading {...LoadingProps}>
        <Grid container item spacing={4} direction='column'>
          <Grid container item spacing={4} justify='space-evenly'>
            <SquareCard text='Registered users' color='#2C6DB5' md={squareCardBreakpointMd} {...squareCardProps}>
              {data.registeredUsers}
            </SquareCard>

            <SquareCard text='Paid users' color='#A0A2CE' md={squareCardBreakpointMd} {...squareCardProps}>
              {data.registeredUsersPaid}
            </SquareCard>

            <SquareCard text='Unpaid users' color='black' md={squareCardBreakpointMd} {...squareCardProps}>
              {data.registeredUsers - data.registeredUsersPaid}
            </SquareCard>

            <SquareCard text='Registered Users Today' hide={isFiltering} color='#9DD7DC' md={squareCardBreakpointMd} {...squareCardProps}>
              {data.registeredUsersToday}
            </SquareCard>
          </Grid>

          <Grid container item justify='space-between' spacing={4}>
            <Grid
              container
              item
              xs={12}
              md={PIE_CHART_CONTAINER_MD}
              {...pieChartContainerProps}
            >
              <PieChart
                passed={data.examineesPassed}
                failed={data.examineesFailed}
                noResponse={data.examineesNoResponse}
              />
            </Grid>

            <Grid
              container
              item
              xs={12}
              md={LONG_CARDS_CONTAINER_MD}
              spacing={4}
              direction='column'
              {...longCardsContainerProps}
            >
              <LongCard text={earningsPeriodText} color='black' {...longCardProps}>
                {`${displayMoney(data.earningsPeriod)}`}
              </LongCard>

              <LongCard text='Earnings this month' color='#5076DD' {...longCardProps}>
                {`${displayMoney(data.earningsMonth)}`}
              </LongCard>

              <LongCard text='Earnings this year' color='#35C78A' {...longCardProps}>
                {`${displayMoney(data.earningsYear)}`}
              </LongCard>

              <LongCard text='Modules Completed' color='#40B8CB' {...longCardProps}>
                {data.modulesCompleted}
              </LongCard>

              <LongCard text='Pending Requests' color='#F5C24C' {...longCardProps}>
                {data.pendingRequests}
              </LongCard>
            </Grid>
          </Grid>
        </Grid>

        <Grid container item justify='flex-start'>
          <Grid item>
            <SiteVisitors />
          </Grid>
        </Grid>
      </Loading>
    </Grid>
  )
}

AdminDashboard.propTypes = {
  squareCardProps: PropTypes.object,
  pieChartContainerProps: PropTypes.object,
  longCardsContainerProps: PropTypes.object,
  longCardProps: PropTypes.object,
}

AdminDashboard.defaultProps = {
  CustomHeader: WelcomeHeader,
}

export default memo(AdminDashboard);
