import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Field as FormikField } from 'formik';
import { TextField } from 'formik-material-ui';

// https://github.com/mui-org/material-ui/issues/12255#issuecomment-558645344:
const useStyles = makeStyles((theme) => ({
  label: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    right: 22,
    bottom: 0,
  },
  // labelShrink: {
  //   right: 'unset',
  // },
}));

const Field = props => {
  const classes = useStyles();

  return (
    <FormikField
      InputLabelProps={{
        classes: {
          root: classes.label,
          // shrink: classes.labelShrink
        },
      }}
      variant='outlined'
      fullWidth
      {...props}
    />
  )
}

Field.defaultProps = {
  component: TextField,
}

export default memo(Field);
