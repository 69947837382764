import React, { memo } from 'react';
import CourseApprovalsBase from 'components/pages/CourseApprovalsPage';

const ReportPageCourseApprovals = props => {
  return (
    <CourseApprovalsBase
      disablePagination={true}
      hideActions={true}
      hideStatus={true}
      hideAppliedOn={true}
      {...props}
    />
  )
}

export default memo(ReportPageCourseApprovals);
