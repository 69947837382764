const COURSE_STATUS = {
  pendingPayment: 'PENDING_PAYMENT',
  pendingApproval: 'PENDING_APPROVAL',
  accepted: 'ACCEPTED',
  rejected: 'REJECTED',
}

const EXAM_TYPE = {
  review : 'REVIEW',
  trial: 'TRIAL',
  actual: 'ACTUAL', // not the best name potentially
}

// REMINDER: These are 0-100 and not 0-1
// Be sure to multiply if needed before doing comparisons
const PASSING_PERCENT = {
  exam: 60,
  course: 70,
}

const MAX_FILE_SIZE = 20 * 1024 * 1024;

const QUESTION_SECONDS_PER_PAGE = 60

module.exports = {
  COURSE_STATUS,
  EXAM_TYPE,
  PASSING_PERCENT,
  MAX_FILE_SIZE,
  QUESTION_SECONDS_PER_PAGE
}
