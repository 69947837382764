import React, { memo, useState } from 'react';
import Context, { CONTEXT_DEFAULT } from './context';
import Dialog from './Dialog';

const GlobalDialogProvider = ({ children }) => {
  const [isOpen, setOpen] = useState(CONTEXT_DEFAULT.isOpen);
  const [message, setMessage] = useState(CONTEXT_DEFAULT.message);
  const [title, setTitle] = useState(CONTEXT_DEFAULT.title);
  const [actions, setActions] = useState(CONTEXT_DEFAULT.actions);

  return (
    <Context.Provider value={{
      isOpen,
      setOpen,
      message,
      setMessage,
      title,
      setTitle,
      actions,
      setActions,
    }}>
      {children}

      <Dialog />
    </Context.Provider>
  )
}

export default memo(GlobalDialogProvider);
