import React, { memo } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 32,
    paddingBottom: 32,
    '@media print': {
      pageBreakBefore: 'always',
      minHeight: '100vh',
      overflow: 'hidden',
    },
  },
  typography: {
    paddingBottom: 32,
  },
}));

const ReportPageItem = ({ children, label }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      { label && (
        <Typography
          variant='h4'
          classes={{ root: classes.typography }}
        >
          {label}
        </Typography>
      )}

      {children}
    </div>
  )
}

export default memo(ReportPageItem);
