import React, {
  // useContext,
  // useEffect,
  useMemo,
  useState
} from 'react'
// Material
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
// GrapQL
import { useQuery } from 'utils';
import { EXAM_RESULT } from 'constants/queries';
// Router
import { useLocation } from 'react-router-dom';
// 
import { useSnackbar } from 'notistack';
//
// import { PASSING_PERCENT } from '@nereus/shared/constants';

function useURLQuery () {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search])
}

const ExaminerResult = () => {
  const query = useURLQuery()
  const { enqueueSnackbar } = useSnackbar();
  const [info, setInfo] = useState({})

  // console.log(query.get('token'))
  useQuery(EXAM_RESULT, {
    enqueueSnackbar,
    errorMessage: 'Error fetching exam result',
    variables: { token: query.get('token') },
    onSuccess(response) {
      setInfo(response.getExamResult)
    },
    onError(error) {
      console.error('error', error)
    }
  });
  return (
    <Grid container spacing={10} style={{marginTop: 0, height: '100%'}} alignItems="center" justify="center">
      <Grid container item xs={10} sm={5}>
        {/*<Grid item xs={12} align="center">
          <img className={`w-1/2`} src="/images/panama-logo-square.png" alt='header-logo'/>
        </Grid>*/}
        <Grid container item xs={12} style={{background: '#FFF', padding: '15px 25px 0 25px'}} spacing={1}>
          <Grid item xs={4}>
            <b>Course:</b>
          </Grid>
          <Grid item xs={8}>
            <span>{info?.course}</span>
          </Grid>
          <Grid item xs={4}>
            <b>Examinee:</b>
          </Grid>
          <Grid item xs={8}>
            <span>{info?.examinee}</span>
          </Grid>
          <Grid item xs={4}>
            <b>Exam Date:</b>
          </Grid>
          <Grid item xs={8}>
            <span>{info?.examDate}</span>
          </Grid>
          <Grid item xs={4}>
            <b>Rating:</b>
          </Grid>
          <Grid item xs={8}>
            <span>{parseFloat(info.rating || 0).toFixed(2)}%</span>
          </Grid>
          <Grid item xs={12} align="center" style={{padding: '30px 0'}}>
            <Typography variant="h3">
              {
                !!info?.passed ? <span style={{color: green[500]}}>PASSED</span>
                  : <span style={{color: red[500]}}>FAILED</span>
              }
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ExaminerResult