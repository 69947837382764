import React, { memo } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DatePicker } from 'formik-material-ui-pickers';
import { get } from 'lodash';
import { useSnackbar } from 'notistack';

import { signup as SIGNUP_VALIDATION }from '@nereus/shared/validation';
import { RANKS } from 'constants/queries';
import { REGISTER } from 'constants/mutations';
import { login as loginAction } from 'reduxConfig/actions/currentUser';
import { DATEPICKER_FORMAT } from 'constants/etc';
import { useMutation } from 'utils';
import Field from './_Field';
import Form from './_Form';
import SelectField from './_SelectFieldWithLoading';

const useStyles = makeStyles((theme) => ({
  image: {
    backgroundImage: "url('/images/reg_bg.png')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '100vh',
  },
}));

const RegisterPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [mutate] = useMutation(REGISTER, {
    enqueueSnackbar,
    rethrowError: true,
    successMessage: 'Registered successfully. Logging in...',
    errorMessage: 'Error registering',
    onCompleted: (data, g) => {
      g(data);
      dispatch(loginAction({...get(data, 'signup', {})}));
      history.push('/courses');
    },
  });

  return (
    <Form
      containerImageClass={classes.image}
      headerText='Register'
      initialValues={{
        username: '',
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        password: '',
        password2: '',
        rank: '',
        graduatedFrom: '',
        graduatedOn: null,
        birthday: null,
        address: '',
        phoneNumber: '',
        company: '',
        referredBy: '',
      }}
      validationSchema={SIGNUP_VALIDATION}
      initialTouched={{ rank: true }}
      validateOnMount={true}
      onSubmit={async (variables, { setSubmitting }) => {
        try {
          await mutate({ variables: {
            ...variables,
            rank: Number(variables.rank),
          }});
        } catch (e) {
          setSubmitting(false);
        }
      }}
    >
      <Grid container spacing={2}>
        {/* TODO Make this part DRYer */}
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={6}>
            <Field name='username' label='Username*' />
          </Grid>

          <Grid item xs={6}>
            <Field name='firstName' label='First Name*' />
          </Grid>
        </Grid>

        <Grid container item xs={12} spacing={2}>
          <Grid item xs={6}>
            <Field name='email' label='Email*' />
          </Grid>

          <Grid item xs={6}>
            <Field name='middleName' label='Middle Name*' />
          </Grid>
        </Grid>

        <Grid container item xs={12} spacing={2}>
          <Grid item xs={6}>
            <Field name='phoneNumber' label='Phone Number*' />
          </Grid>

          <Grid item xs={6}>
            <Field name='lastName' label='Last Name*' />
          </Grid>
        </Grid>

        <Grid container item xs={12} spacing={2}>
          <Grid item xs={6}>
            <Field name='password' type='password' label='Password*' />
          </Grid>

          <Grid item xs={6}>
            <Field
              name='birthday'
              label='Birthday*'
              component={DatePicker}
              inputVariant='outlined'
              format={DATEPICKER_FORMAT}
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} spacing={2}>
          <Grid item xs={6}>
            <Field label='Confirm Password*' name='password2' type='password' />
          </Grid>

          <Grid item xs={6}>
            <SelectField
              query={RANKS}
              responseDataPath='ranks'
              label='Rank*'
              name='rank'
            />
          </Grid>
        </Grid>
        
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={6}>
            <Field name='address' label='Address*' />
          </Grid>

          <Grid item xs={6}>
            <Field name='graduatedFrom' label='Graduated from*' />
          </Grid>
        </Grid>

        <Grid container item xs={12} spacing={2}>
          <Grid item xs={6}>
            <Field name='company' label='Company' />
          </Grid>

          <Grid item xs={6}>
            <Field
              name='graduatedOn'
              label='Graduated on*'
              component={DatePicker}
              inputVariant='outlined'
              format={DATEPICKER_FORMAT}
            />
          </Grid>
        </Grid>
        
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={6}>
            <Field name='referredBy' label='Referred by' />
          </Grid>
        </Grid>
      </Grid>
    </Form>
  )
}

export default memo(RegisterPage);
