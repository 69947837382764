import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Item from './Item';

const useStyles = makeStyles((theme) => ({
  typographyName: {
    marginLeft: 8*4,
  },
  itemsContainer: {
    marginTop: 8*1,
  },
}));

const CourseCategory = ({
  name,
  items,
  showCategoryItems,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Grid item container justify='space-between'>
      <Grid item xs={12}>
        <Typography className={classes.typographyName}>
          {name}
        </Typography>
      </Grid>

      {
        showCategoryItems && items.length > 0 && (
          <Grid item container xs={12} classes={{ root: classes.itemsContainer }}>
            {items.map(item => (
              <Item key={item.id} {...rest} {...item} />
            ))}
          </Grid>
        )
      }
    </Grid>
  )
}

CourseCategory.propTypes = {
  name: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  showCategoryItems: PropTypes.bool,
}

CourseCategory.defaultProps = {
  items: [],
  showCategoryItems: false,
}

export default memo(CourseCategory);
