import React, { memo, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, TextField, FormControl, FormHelperText } from '@material-ui/core';
import { KeyboardDatePicker, TimePicker } from '@material-ui/pickers'
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useSnackbar } from 'notistack';
import moment from "moment";
import ExamSchedules from './ExamSchedulesPage'
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

import { login as LOGIN_VALIDATION } from '@nereus/shared/validation';
import { LOGIN as LOGIN_MUTATION } from 'constants/mutations';
import { login as loginAction } from 'reduxConfig/actions/currentUser';
import { useMutation } from 'utils';
// import Field from './_Field';
// import Form from './_Form';
import { EDIT_SCHEDULE } from 'constants/mutations';

import * as yup from 'yup'
import { useFormik } from 'formik';

const useStyles = makeStyles((theme) => ({
  image: {
    backgroundImage: "url('/images/main_background.png')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    paddingLeft: 40,
    paddingRight: 40,
    minHeight: '100vh',
  },
}));

const EditExamScheduleModal = ({
  closeDialog,
  toggleShowModal,
  idExam,
  dateExam,
  timeExam,
  totalExam,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const validation = useFormik({
    initialValues: {
      examDate: dateExam ? moment(dateExam).format('MM-DD-YYYY') : moment().format('MM-DD-YYYY'),
      time: timeExam ? moment('01-01-2000 '+timeExam, 'MM-DD-YYYY h:m a').format('h:mm a') : moment().format('h:mm a'),
      participants: totalExam || 0
    },
    validationSchema: yup.object({
      examDate: yup.string()
        .required('The date field is required.')
        .test(
          'is-morethan-date',
          'The date field is should be at future.',
          (value, context) => {
            const date = moment(value, 'MM-DD-YYYY')
            if(date.isValid()) {
              return date.isSameOrAfter(moment(dateExam, 'MM-DD-YYYY'))
            }
            return false;
          }
        ),
      time: yup.string()
        .required('The time field is required.'),
      participants: yup.number()
        .integer('The participants field is should be integer.')
        .max(999, 'The participants field max values is 999.')
    }),
    onSubmit(values) {
      mutation({
        variables: {
          examid: idExam,
          setdate: values.examDate,
          settime: values.time,
          participants: values.participants,
        },
      });
    }
  })


  const [selectedDate, setDate] = useState(moment());
  const [inputValue, setInputValue] = useState(dateExam ? dateExam : moment().format("MM-DD-YYYY"));

  const onDateChange = (date, value) => {
    // console.log('onDateChange', date, value, date.format('MM-DD-YYYY'))
    // setDate(date);
    // setInputValue(value);
    validation.setFieldValue('examDate', value)
  };
  const [selectedTime, setTime] = useState(moment());
  const [inputValueTime, setInputValueTime] = useState(timeExam ? timeExam : moment().format("h:m a"));

  const onTimeChange = (time, value) => {
    // console.log(time, value)
    validation.setFieldValue('time', time.format('h:mm a'))
  };
  const [selectedPart, setPart] = useState(moment());
  const [ inputParticipants, setInputParticipants ] = useState(totalExam);
  const onPartChange = (value) => {
    validation.setFieldValue('participants', Number(value) || 0)
  };

  const handleClickConfirm = (evt) => {

    evt.preventDefault();
    validation.handleSubmit()
  }

  const [mutation, { loading: isLoading }, mutationResults] = useMutation(
    EDIT_SCHEDULE,
    {
      onCompleted: (data) => {
        console.log(data);
        window.location='/admin/exam-schedules';
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  useEffect(() => {
    setTime(moment(timeExam, 'h:mm a')._d)
    setInputParticipants(totalExam)
  }, [
    idExam,
    dateExam,
    timeExam,
    totalExam
  ])

  return (
    <Modal
      open={true}
      center={true}
      showCloseIcon={false}
      onClose={() => toggleShowModal()}
    >
    <Grid>
      <FormControl>
        <Grid container item spacing={2}>
          <Grid container item>
            <KeyboardDatePicker
              minDate={moment(dateExam)._d}
              variant='outlined'
              fullWidth
              format="MM-DD-YYYY"
              name='date'
              label='Date'
              value={validation.values.examDate}
              onChange={onDateChange}
              inputValue={validation.values.examDate}
              errors={Boolean(validation.errors.examDate)}
            />
            {
              Boolean(validation.errors.examDate) && (
                <FormHelperText error>{validation.errors.examDate}</FormHelperText>
              )
            }
          </Grid>

          <Grid container item>
            <TimePicker
              label='Time'
              name='time'
              fullWidth
              format="h:mm a"
              onChange={onTimeChange}
              value={moment('01-01-2000 ' +validation.values.time, 'MM-DD-YYYY h:mm a')}
            />
            {
              validation.errors.time && (
                <FormHelperText error>{validation.errors.time}</FormHelperText>
              )
            }
          </Grid>

          <Grid container item>
            <TextField
              label='No. of Participants'
              name='participants'
              fullWidth
              type="number"
              value={validation.values.participants}
              InputProps={{ inputProps: { min: 0 } }}
              error={validation.errors.participants}
              helperText={Boolean(validation.errors.participants) && validation.errors.participants}
              onInput={event => onPartChange(event.target.value)}
            />
          </Grid>
        </Grid>
      </FormControl>
      <Grid className='mt-5 text-right'>
        <Button
          variant='contained'
          className='bg-green-400 text-white'
          onClick={handleClickConfirm}
        >
          Confirm
        </Button>
        &nbsp;
        <Button
          variant='contained'
          onClick={closeDialog}
          autoFocus
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
    </Modal>
  )
}

export default memo(EditExamScheduleModal);
