import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  // Box,
  Button,
  Grid,
  Typography,
  Box
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
// import { makeStyles } from '@material-ui/core/styles';
// import CorrectIcon from '@material-ui/icons/CheckCircleOutline';
// import WrongIcon from '@material-ui/icons/RemoveCircleOutline';
// import TotalIcon from '@material-ui/icons/FilterNone';
// import PercentageIcon from '@material-ui/icons/TrackChanges';

import { checkIfShowCoursesInExamMode } from 'reduxConfig/selectors';
import { renderComponentOrFunction } from 'utils';

import { EXAM_LIST_PAGE as QUERY } from 'constants/queries';
import { useParams } from 'react-router-dom';
import { useQuery } from 'utils';
import { useSnackbar } from 'notistack';
import {
  get,
  // flatten
} from 'lodash';
// import { PASSING_PERCENT } from '@nereus/shared/constants';
import CircularProgress from '@material-ui/core/CircularProgress';

import moment from 'moment';



// const useStyles = makeStyles((theme) => ({
//   container: {
//     paddingTop: 8*5,
//   },
//   icon: {
//     width: '100%',
//     maxWidth: '4vh',
//     height: 'auto',
//   },
//   correctIcon: {
//     color: green[500],
//   },
//   wrongIcon: {
//     color: red[500],
//   },
// }));

// const Row = ({
//   Icon = CorrectIcon,
//   classes,
//   label,
//   value,
// }) => (
//   <Grid container item>
//     <Grid container item xs={6} justify='center'>
//       <Icon classes={{ root: classes }} />
//     </Grid>

//     <Grid container item xs={6} justify='center' alignItems='center'>
//       <Typography variant='h5' align='center'>
//         <Box component='span' fontWeight='fontWeightBold'>{label}:&nbsp;</Box>
//         <Box component='span'>{value}</Box>
//       </Typography>
//     </Grid>
//   </Grid>
// )

// const ExamScoreRow = ({
//   // Classes,
//   id,
//   label,
//   correctAns,
//   wrongAns,
// }) => (
//   <Grid key={id} container item>
//     <Grid container item xs={6} justify='center'>
//         <p>{label}</p>
//     </Grid>

//     <Grid container item xs={6} justify='center' alignItems='center'>
//       <Typography variant='h5' align='center'>
//         <p>{correctAns}/{wrongAns}</p>
//       </Typography>
//     </Grid>
//   </Grid>
// )

const DefaultFooter = memo(() => {
  const history = useHistory();
  const showCoursesInExamMode = useSelector(checkIfShowCoursesInExamMode);

  // This check is done because in the special case where you can
  // view "All Courses" while in exam mode (i.e. you're FLIGNO or ADMIN),
  // we want to make sure the user is sent to 'exam-list'.
  //
  // For all other cases, just redirect to 'courses',
  // and if user is not logged in the other redirects will take you home:
  const page = showCoursesInExamMode ? 'exam-list' : 'courses';

  return (
    <Grid container item justify='center' xs={4}>
      <Button
        variant='contained'
        color='primary'
        fullWidth
        size='large'
        onClick={() => history.push(`/${page}`)}
      >
        Go back
      </Button>
    </Grid>
  )
})

const ExamResults = ({
  subtitle,
  questionsCount,
  correctAnswerCount,
  Footer,
}) => {
  // const classes = useStyles();

  // const wrongAnswerCount = questionsCount - correctAnswerCount;
  // const percentageCorrect = ((correctAnswerCount / questionsCount) * 100).toFixed(2);

  const { courseId, examId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  // const [ecategory, setECategory] = useState([]);

  const { data, LoadingProps } = useQuery(QUERY, {
    enqueueSnackbar,
    errorMessage: 'Error fetching your exams',
    variables: { courseId },
  });

  const categories = get(data, 'courseCategoriesWithExamFlag.courseCategories', []);
  const functionsWithCompetence = get(data, 'courseCategoriesWithExamFlag.functionsWithCompetence', [])
  const examFunction = functionsWithCompetence.find(item => item.exams[0].id == examId)
  let competences = null
  if(examFunction) {
    competences = examFunction.items
  }
  const headerStyle = {
    borderBottom: '1px solid #a7b5c4',
    padding: '10px 0'
  }
  const rowStyle = {
    padding: '10px 0 10px 5px'
  }
  return (
    <Grid
      container
      item
      spacing={0}
      xs={12}
    >
      <Grid container item xs={12}>
        <Box style={{width: '100%'}}>
          <Typography variant='h4' align="center" className="mb-2">{subtitle}</Typography>
          <Typography variant='h3' align="center" className="mb-2">Exam Results</Typography>
        </Box>
      </Grid>
      {/*<span>{count()}</span>*/}
      <Grid item xs={12} spacing={4} className="border-2 border-gray-500 rounded-md bg-gray-200 mb-8">
        {
          LoadingProps.loading &&
          <Grid item xs={12} container style={{padding: 50}}>
            <Grid item xs={12} align='center'>
              <CircularProgress />
            </Grid>
            <Grid item xs={12}  align='center'>
              <span>Calculating. . .</span>
            </Grid>
          </Grid>
        }
        {
          (!LoadingProps.loading && !!competences) &&
          <>
            <Grid item xs={12} container>
              <Grid align='center' xs={7} style={headerStyle}>
                <b>COMPETENCE</b>
              </Grid>
              <Grid align='center' xs={1} style={headerStyle}>
                <b>RATING</b>
              </Grid>
              <Grid align='center' xs={2} style={headerStyle}>
                <b>REMARKS</b>
              </Grid>
              <Grid align='center' xs={2} style={headerStyle}>
                <b>DATE TAKEN</b>
              </Grid>
            </Grid>
            {
              competences.map((competence, i) => {
                return (
                  <Grid item xs={12} container>
                    <Grid xs={7} style={rowStyle}>
                      <b>{competence.name}</b>
                    </Grid>
                    <Grid xs={1} align="center" style={rowStyle}>
                      <b>{competence.average.toFixed(2)}%</b>
                    </Grid>
                    <Grid xs={2} align="center" style={rowStyle}>
                      {/*{competence.average.toFixed(2) >= PASSING_PERCENT.exam ? <b style={{color: green[500]}}>PASSED</b> : <b style={{color: red[500]}}>FAILED</b>}*/}
                      {competence.passed ? <b style={{color: green[500]}}>PASSED</b> : <b style={{color: red[500]}}>FAILED</b>}
                    </Grid>
                    <Grid xs={2} align="center" style={rowStyle}>
                      <b>{moment(competence.dateTaken).format('YYYY-MM-DD')}</b>
                    </Grid>
                  </Grid>
                )
              })
            }
          </>
        }
        
        
        
      </Grid>

      
      <Grid container item justify='center'>
        {renderComponentOrFunction(Footer)}
      </Grid>
    </Grid>
  )
}

ExamResults.propTypes = {
  subtitle: PropTypes.string,
  questionsCount: PropTypes.number.isRequired,
  correctAnswerCount: PropTypes.number,
  Footer: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
}

ExamResults.defaultProps = {
  questions: [],
  answers: {},
  correctAnswerCount: 0,
  handleChangeRadio: noop,
  firstQuestionNumber: 1,
  Footer: DefaultFooter,
}

export default memo(ExamResults);
