import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography, Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { GET_USER_TAKING_EXAM_FOR_COURSE_ID as QUERY } from 'constants/queries';
import { TOGGLE_EXAM_MODE as MUTATION } from 'constants/mutations';
import { useGlobalDialog } from 'globalDialog';
import {
  Actions as ConfirmTakeExamActions,
  Title as ConfirmTakeExamTitle,
} from 'components/common/Courses/Course/TakeExamsButton';
import Paragraphs from 'components/common/Paragraphs';
import { useMutation } from 'utils';

const CONFIRM_TAKE_EXAM_MESSAGE = [
  'Please proceed with caution. Once you choose to take the exam,  you will not be able to exit the exam page unless you finish answering all the exam parts for the different functions of the course.',
  'For example, if the exam has four (4) parts, you need to finish all four (4) before you are allowed to exit the exam page and continue your review. Overall, you will have a maximum of eight (8) attempts to take the exam and pass it.',
  'By clicking to proceed, you agree that you have read the information above and you are aware of the possible outcomes for continuing.',
];

const TakeExamsButton = ({ courseId, disabled }) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    open: openDialog,
    close: closeDialog,
  } = useGlobalDialog();
  
  const [mutate] = useMutation(MUTATION, {
    enqueueSnackbar,
    successMessage: null,
    errorMessage: 'Error sending request',
    variables: { courseId },
    update: (store, { data: { toggleExamMode: takingExamForCourseId }}) => {
      store.writeQuery({ query: QUERY, data: { currentUser: { takingExamForCourseId }}});
    },
  });

  const handleClick = event => {
    event.stopPropagation();

    openDialog({
      message: <Paragraphs data={CONFIRM_TAKE_EXAM_MESSAGE} />,
      title: <ConfirmTakeExamTitle />,
      actions: <ConfirmTakeExamActions takeExam={mutate} closeDialog={closeDialog} />,
    });
  }
  
  return (
    <Button
    variant='contained'
    className={disabled ? '' : 'bg-green-400 text-white'}
    onClick={handleClick}
    disabled={disabled}
    >
      Take Exam(s)
    </Button>
  )
}

TakeExamsButton.propTypes = {
  courseId: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
}

TakeExamsButton.defaultProps = {
  disabled: false,
}

export default memo(TakeExamsButton);
