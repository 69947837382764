import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { identity } from 'lodash';
import TableRowCollapse from './TableDataRowCollapse';

const useStyles = makeStyles((theme) => ({
  container: {
    cursor: 'pointer',
  },
}));

const getCellData = (row, column) => {
  if (column.render) {
    return column.render(row);
  }
  
  return (column.format || identity)(
    column.get
      ? column.get(row)
      : row[column.field]
  );
}

const TableDataRow = ({
  row,
  columns,
  renderRowExpand,
}) => {
  const classes = useStyles();
  
  return (
    <TableRowCollapse
      row={row}
      numberOfColumns={columns.length}
      renderRowExpand={renderRowExpand}
    >
      {({ toggleExpand }) => (<>
        <TableRow
          key={row.id}
          onClick={toggleExpand}
          classes={{ root: classes.container }}
        >
          {columns.map(column => (
            <TableCell key={column.label}>
              {getCellData(row, column)}
            </TableCell>
          ))}
        </TableRow>
      </>)}
    </TableRowCollapse>
  )
}

TableDataRow.propTypes = {
  row: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  renderRowExpand: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
}

export default memo(TableDataRow);
