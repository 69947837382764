import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { green, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import CorrectIcon from '@material-ui/icons/CheckCircleOutline';
import WrongIcon from '@material-ui/icons/RemoveCircleOutline';

const useStyles = makeStyles((theme) => ({
  correctIcon: {
    color: green[500],
    marginLeft: 5
  },
  wrongIcon: {
    color: red[500],
    marginLeft: 5
  },
}));

const ExamListPagePercentDisplay = ({
  percent,
  passingPercent,
}) => {
  const classes = useStyles();

  if (!percent && percent !== 0) return null;

  const success = percent >= passingPercent;
  const percentText = `${percent.toFixed(2)}%`;

  const Icon = success ? CorrectIcon : WrongIcon;

  return (<>
    {percentText}
    
    <Icon classes={{ root: success ? classes.correctIcon : classes.wrongIcon }} />
  </>)
}

ExamListPagePercentDisplay.propTypes = {
  percent: PropTypes.number,
  passingPercent: PropTypes.number,
}

ExamListPagePercentDisplay.defaultProps = {
  passingPercent: 0,
}

export default memo(ExamListPagePercentDisplay);
