import React, { memo, useEffect } from 'react';
import { Switch, Link, Redirect } from 'react-router-dom';
// eslint-disable-next-line
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Route as RouteBase } from 'react-router-dom';
import { get, noop } from 'lodash';
import 'fontsource-roboto';

import { LOG_SITE_VISIT } from 'constants/mutations';
import { setSiteVisitors } from 'reduxConfig/actions/misc';
import { updateUser } from 'reduxConfig/actions/currentUser';
import { checkIfAdmin, checkIfShowCoursesInExamMode } from 'reduxConfig/selectors';
import { checkInDevMode, useMutation } from 'utils';
import Route, { PublicRoute, PrivateRoute, AdminRoute } from './Route';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ServicesPage from './pages/ServicesPage';
import ContactPage from './pages/ContactPage';
import CoursesPage from './pages/CoursesPage';
import CoursesTakeExamPage from './pages/CoursesTakeExamPage';
import LoginPage from './pages/LoginRegisterProfile/LoginPage';
import RegisterPage from './pages/LoginRegisterProfile/RegisterPage';
import ProfileUpdatePage from './pages/LoginRegisterProfile/ProfileUpdatePage';
import AdminDashboardPage from './pages/AdminDashboardPage';
import ExamSchedulesPage from './pages/ExamSchedulesPage';
import CourseApprovalsPage from './pages/CourseApprovalsPage';
import UsersPage from './pages/UsersPage';
import ReportPage from './pages/ReportPage';
import LogoutPage from './pages/LogoutPage';
import BlankPage from './pages/BlankPage';
import ExamListPage from './pages/ExamListPage';
import ExamResultsPage from './pages/ExamListPage/ExamResultsPage';
import ExamPage from './pages/ExamPage';
import ExaminerResult from './pages/Examiner/Result'
import SettingsPage from './pages/AdminDashboardPage/Settings'
import {
  TrialExamPage,
  ReviewPage,
} from './pages/TrialExamOrReviewPage';
import { version as clientVersion } from '../../package.json';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    minHeight: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    flex: 1,
  },
  red: { backgroundColor: 'red', height: 42 },
  blue: { backgroundColor: 'blue' },
  green: { backgroundColor: 'green' },
}));

const App = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isAdmin = useSelector(checkIfAdmin);
  const showCoursesInExamMode = useSelector(checkIfShowCoursesInExamMode);

  const [logSiteVisit] = useMutation(LOG_SITE_VISIT, {
    onCompleted: data => {
      // TODO Replace various uses of get() with ?.
      const {
        serverVersion,
        serverEnv,
        count = 0,
        user,
      } = get(data, 'logSiteVisit', {});
      const clientEnv = process.env.NODE_ENV;

      if (checkInDevMode() || isAdmin) {
        console.log({
          clientVersion,
          clientEnv,
          serverVersion,
          serverEnv,
          user,
        });

        if (!serverEnv) {
          console.warn(`The server's NODE_ENV is not set, this should act as "development"`);
        }
      }

      dispatch(setSiteVisitors(count));

      if (user) dispatch(updateUser(user))
    },
  });

  useEffect(() => { logSiteVisit(); return noop; }, [logSiteVisit]);

  // This was used to test a fix for the layout.
  // Keeping it for now in case needed again.
  // return (
  //   <div className={classes.mainContainer}>
  //     <div className={classes.red}>RED</div>
  //     <Grid item container xs={12} classes={{ root: classes.contentContainer }}>
  //       <Grid item container classes={{container:classes.blue}} xs={3}>BLUE</Grid>
  //       <Grid item container classes={{container:classes.green}} xs={9}>GREEN</Grid>
  //     </Grid>
  //   </div>
  // )

  return (
    <div className={classes.mainContainer}>
      <Switch>
        <PublicRoute exact path='/' component={HomePage} isHomePage={true} />
        <PublicRoute exact path='/services' component={ServicesPage} />
        <PublicRoute exact path='/contact' component={ContactPage} />
        <PublicRoute exact path='/login' component={LoginPage} />
        <PublicRoute exact path='/register' component={RegisterPage} />
        <PublicRoute exact path='/about' component={AboutPage} />
        <PublicRoute exact path='/examiner/result' component={ExaminerResult} />

        <PrivateRoute exact path='/courses' component={CoursesPage} examMode={false} skipExamModeCheck={showCoursesInExamMode} />
        <PrivateRoute exact path='/course-exam-list' component={CoursesTakeExamPage} examMode={false} />
        <PrivateRoute exact path='/exam-list' component={ExamListPage} examMode={true} />
        <PrivateRoute exact path='/exam-results' component={ExamResultsPage} sidebar={false} />
        <PrivateRoute exact path='/exam-results/:courseId/:userId' component={ExamResultsPage} sidebar={false} />
        {/* DEPRECATED: <PrivateRoute exact path='' component={ExamListPage} examMode={true} /> */}
        <Redirect from='/exam-list/:courseId' to='/exam-list' />
        <PrivateRoute exact path='/profile-edit' component={ProfileUpdatePage} />

        <AdminRoute exact path='/admin/dashboard' component={AdminDashboardPage} />
        <AdminRoute exact path='/admin/exam-schedules' component={ExamSchedulesPage} />
        <AdminRoute exact path='/admin/approve-deny-payments' component={CourseApprovalsPage} />
        <AdminRoute exact path='/admin/users' component={UsersPage} />
        <AdminRoute exact path='/admin/report' component={ReportPage} sidebar={false} />
        <AdminRoute exact path='/admin/settings' component={SettingsPage}/>

        <Route path='/exam-trial/:examId' component={TrialExamPage} examMode={false} />
        <PrivateRoute path='/review/:courseItemId' component={ReviewPage} examMode={false} />
        <PrivateRoute path='/exam/:examId' component={ExamPage} examMode={true} />
        
        <RouteBase exact path='/logout' component={LogoutPage} />

        <Route exact path='/blank' component={BlankPage} />
        <RouteBase exact path='/very-blank' component={BlankPage} />

        {/* TEMP Disabled automatic redirect on 404 */}
        {/* <Redirect to ='/' /> */}
        <RouteBase>
          <div>Page not found. Pls click <Link to='/'>here</Link></div>
        </RouteBase>
      </Switch>
    </div>
  )
}

export default memo(App);
