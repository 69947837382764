import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { FormControl, MenuItem } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { get, noop } from 'lodash';
import { useSnackbar } from 'notistack';

import Loading from 'components/common/Loading';
import { useQuery } from 'utils';

// TODO Have "id" and "name" be keys we can customize
const renderOption = ({ id, name }) => (
  <MenuItem key={id} value={id}>{name}</MenuItem>
)

// See https://stackworx.github.io/formik-material-ui/docs/guide/faq#validation-errors-missing-from-select
// for docs on validation:
const SelectFieldWithLoading = ({
  query,
  responseDataPath,
  label,
  onCompleted,
  ...rest
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const { loading, data, LoadingProps } = useQuery(query, {
    enqueueSnackbar,
    onCompleted,
    errorMessage: `Error loading options for ${label}`,
  });

  const options = loading ? [] : (
    responseDataPath ? get(data, responseDataPath, []) : data
  )

  return (
    <FormControl fullWidth>
      <Loading {...LoadingProps} padding={8*2}>
        <Field
          component={TextField}
          select={true}
          InputLabelProps={{ shrink: true }}  
          fullWidth
          disabled={loading}
          label={label}
          {...rest}
        >
          {options.map(renderOption)}
        </Field>
      </Loading>
    </FormControl>
  )
}

SelectFieldWithLoading.propTypes = {
  query: PropTypes.object.isRequired,
  onCompleted: PropTypes.func,
  responseDataPath: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string,
}

SelectFieldWithLoading.defaultProps = {
  onCompleted: noop,
  label: 'Rank',
  variant: 'outlined',
}

export default memo(SelectFieldWithLoading);
