import React, { memo } from 'react';
import { Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { get, flatten } from 'lodash';
import { useSnackbar } from 'notistack';
import { green, red } from '@material-ui/core/colors';

// import { PASSING_PERCENT } from '@nereus/shared/constants';
import { EXAM_LIST_PAGE as QUERY } from 'constants/queries';
import NoPrint from 'components/common/NoPrint';
import B from 'components/common/B';
import Loading from 'components/common/Loading';
import Exam from './Exam';
import { Button } from '@material-ui/core';
import ExitExamModeButton from './ExitExamModeButton';
// import ExamPrintReportButton from './ExamPrintReportButton';
import PercentDisplay from './PercentDisplay';
import PrintIcon from '@material-ui/icons/Print';
// import {
//   NAME_XS,
//   EXAM_XS,
//   SCORE_XS,
//   PERCENT_XS,
//   ATTEMPTS_XS,
//   BUTTON_XS,
// } from './constants';
import { useQuery } from 'utils';
// import { getCurrentUserID } from 'reduxConfig/selectors';
// import { useSelector } from 'react-redux';
import moment from 'moment';

const ExamList = () => {
  const { courseId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  // const [itemScore, setItemScore] = useState([]);
  // const uid = useSelector(getCurrentUserID);
  // const userid = parseInt(uid);
  // const cid = parseInt(courseId);

  const { data, LoadingProps } = useQuery(QUERY, {
    enqueueSnackbar,
    errorMessage: 'Error fetching your exams',
    variables: { courseId },
  });
  
  // const passingRates = get(data, 'courseCategoriesWithExamFlag.setting')
  const categories = get(data, 'courseCategoriesWithExamFlag.courseCategories', []);
  const setting = get(data, 'courseCategoriesWithExamFlag.setting', {})
  const functionsWithCompetence = get(data, 'courseCategoriesWithExamFlag.functionsWithCompetence', []);
  const maxExamAttempts = get(data, 'courseCategoriesWithExamFlag.maxExamAttempts', null);
  const flattenedExams = flatten(categories.map(category => category.exams));
  const allExamsAttempted = flattenedExams.every(x => x.attempts > 0);
  const mostAttemptedExamCount = flattenedExams.reduce((a, { attempts:b }) => Math.max(a, b), 0);
  const allExamsHaveSameAttempts = flattenedExams.every(exam => exam.attempts === mostAttemptedExamCount);
  const totalScore = flattenedExams.reduce((sum, x) => sum + x.score, 0);
  const totalItems = flattenedExams.reduce((sum, x) => sum + x.numberOfQuestions, 0);
  const percent = (totalScore / totalItems) * 100;
  const canExitExam = allExamsAttempted && percent >= setting.passingCourseRate || allExamsHaveSameAttempts;
  // const canExitExam = allExamsAttempted && percent >= PASSING_PERCENT.course || allExamsHaveSameAttempts;
  const openReports = () => {
    window.open('/exam-results', '_blank', 
      Object.entries({
        toolbar: 0,
        location: 0,
        menubar: 0,
        height: Math.min(Math.max(window.screen.height*.5, 1280), window.screen.height*.75),
        width: Math.min(Math.max(window.screen.width*.5, 720), window.screen.width*.75),
      }).map(([k,v]) => `${k}=${v}`).join(',')
    )
  }
  // const showKUPs = (kups) => {
  //   return (
  //     <ul style={{marginLeft: 30}}>
  //       {
  //         kups.map((kup) => (
  //           <li style={{listStyleType: 'disc'}}>{kup}</li>
  //         ))
  //       }
  //     </ul>
  //   )
  // }
  return (
    <Loading {...LoadingProps}>
      <Grid container direction='column' spacing={10}>
        <Grid container item xs={12} justify='space-between' spacing={2}>
          <Grid container item xs={12}>
            <Grid className="py-3 px-4" alignItems='center' container>
              <Grid container item xs={6} justify="center" className='pr-2'><B>Competence</B></Grid>
              <Grid container item xs={6}>
                <Grid item xs={4}><B>Rating</B></Grid>
                <Grid item xs={4}><B>Remarks</B></Grid>
                <Grid item xs={4}><B>Date Taken</B></Grid>
                {/*<NoPrint>
                  <Grid item xs={BUTTON_XS} className='px-4'><B>Exams</B></Grid>
                </NoPrint>*/}
              </Grid>
            </Grid>
          </Grid>

          {functionsWithCompetence.map((category, index) => {
            return (
              <Grid
                key={'category'+category.id}
                container
                item
                xs={12}
              >
                <Grid className="bg-white py-3 px-4 rounded" alignItems='center' container>
                  <Grid container item xs={12}>
                    <p style={{padding: '10px 0'}}><b>{category.name}</b></p>
                  </Grid>
                 
                  {
                    category.items.map((item, index) => {
                      if(!!item.dateTaken) {
                        return (
                         <Grid container item xs={12} spacing={2} key={'items-'+index}>
                          <Grid item xs={6}>
                            <p style={{marginLeft: 10}}>{item.name}</p>
                            {/*{
                              item.kups.length > 0 && showKUPs(item.kups)
                            }*/}
                          </Grid>
                          <Grid container item xs={6}>
                            <Grid item xs={4}><B>{item.average.toFixed(2)}%</B></Grid>
                            <Grid item xs={4}>{item.average.toFixed(2) >= setting.passingExamRate ? <B style={{color: green[500]}}>PASSED</B> : <B style={{color: red[500]}}>FAILED</B>}</Grid>
                            {/*<Grid item xs={4}><B>{item.score}/{item.questions}</B></Grid>*/}
                            <Grid item xs={4}><B>{moment(item.dateTaken).format('YYYY-MM-DD')}</B></Grid>
                            {/*<Grid item xs={3}><B>Exams</B></Grid>*/}
                          </Grid>
                        </Grid>
                        )
                      }
                      return (
                       <Grid container item xs={12} spacing={2}>
                        <Grid item xs={6}>
                          <p style={{marginLeft: 10}}>{item.name}</p>
                          {/*{
                            item.kups.length > 0 && showKUPs(item.kups)
                          }*/}
                        </Grid>
                        {
                          !!item.dateTaken ?
                          <Grid container item xs={6}>
                            <Grid item xs={4}><B>{item.average.toFixed(2)}%</B></Grid>
                            <Grid item xs={4}>{item.average.toFixed(2) >= setting.passingExamRate ? <B style={{color: green[500]}}>PASSED</B> : <B style={{color: red[500]}}>FAILED</B>}</Grid>
                            <Grid item xs={4}><B>{moment(item.dateTaken).format('YYYY-MM-DD')}</B></Grid>
                          </Grid>
                          :
                          <Grid container item xs={6}>
                            <Grid item xs={4}><B>N/A</B></Grid>
                            <Grid item xs={4}><B>N/A</B></Grid>
                            <Grid item xs={4}><B>N/A</B></Grid>
                          </Grid>
                        }
                      </Grid>
                      )
                    })
                  }
                  <Grid item xs={6}>
                    
                  </Grid>
                  <Grid item xs={6}>
                    <Exam
                      passingRate={setting.passingExamRate}
                      average={percent}
                      hasButton={true}
                      exams={categories[index].exams}
                      examItemScores={1}
                      maxExamAttempts={maxExamAttempts}
                      mostAttemptedExamCount={mostAttemptedExamCount}
                      allExamsHaveSameAttempts={allExamsHaveSameAttempts}
                    />
                  </Grid>
                  {/*<Grid container spacing={2}>
                    {category.items.map((item, index) => {
                      return (
                        <>
                          <Grid item xs={5}>
                            <p style={{marginLeft: 10}}>{item.name}</p>
                          </Grid>
                          <Grid item xs={7}>
                            <Exam
                              hasButton={(category.items.length - 1) == index}
                              exams={category.exams}
                              examItemScores={1}
                              maxExamAttempts={maxExamAttempts}
                              mostAttemptedExamCount={mostAttemptedExamCount}
                              allExamsHaveSameAttempts={allExamsHaveSameAttempts}
                            />
                          </Grid>
                        </>
                      )
                    })}
                  </Grid>*/}
                </Grid>
              </Grid>
            )
          })}

          {
            allExamsAttempted && (<>
              <Grid
                container
                item
                xs={12}
              >
                <Grid className="bg-white rounded py-3 px-4" container item xs={12}>
                  <Grid container item xs={6}><B>AVERAGE RATING</B></Grid>
                  <Grid container item xs={6}>
                    <Grid item xs={4} />
                    <Grid item xs={4}>
                      <PercentDisplay percent={percent} passingPercent={setting.passingCourseRate} />
                    </Grid>
                    <Grid item xs={4} />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item xs={12}>
                <Grid container item xs={12}>
                  <NoPrint>
                    <ExitExamModeButton disabled={!canExitExam} />
                  </NoPrint>
                  <Button
                    variant='contained'
                    aria-label='print'
                    className={'ml-2 bg-blue-400 text-white'}
                    onClick={openReports}
                  >
                    <PrintIcon />
                    Print
                  </Button>
                </Grid>
              </Grid>
            </>)
          }
        </Grid>
      </Grid>
    </Loading>
  )
}

export default memo(ExamList);
