import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Formik,
  Field,
  Form,
} from 'formik';
import { DatePicker } from 'formik-material-ui-pickers';

import B from 'components/common/B';
import { DATEPICKER_FORMAT } from 'constants/etc';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 8*4,
  },
  button: {
    minHeight: 8*2,
  },
}));

const AdminDashboardFilters = ({ refetch }) => {
  const classes = useStyles();

  const onResetOrError = async (variables, { setSubmitting }) => {
    try {
      setSubmitting(true);
      await refetch(variables);
    }
    catch (e) {
      // Refetches throw errors even when the original query won't?
      // Unsure if Apollo is WAI here.
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Formik
      initialValues={{
        startDate: null,
        endDate: null,
      }}
      onReset={onResetOrError}
      onSubmit={onResetOrError}
    >
      {({ submitForm, isSubmitting, isValid, resetForm }) => {
        return (
          <Form>
            <Grid 
              container
              spacing={2}
              justify='space-around'
              alignItems='center'
              classes={{ root: classes.container }}
            >
              <Grid
                item
                container
                spacing={2}
                justify='center'
                alignItems='center'
                xs={12}
                lg={8}
                wrap='nowrap'
              >
                <Grid item>
                  <B>Filter data:</B>
                </Grid>

                <Grid item>
                  <Field
                    name='startDate'
                    label='Start date'
                    component={DatePicker}
                    inputVariant='outlined'
                    format={DATEPICKER_FORMAT}
                  />
                </Grid>

                <Grid item>
                  <Field
                    name='endDate'
                    label='End date'
                    component={DatePicker}
                    inputVariant='outlined'
                    format={DATEPICKER_FORMAT}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                spacing={2}
                justify='center'
                xs={12}
                lg={4}
              >
                <Grid item>
                  <Button
                    variant='contained'
                    color='secondary'
                    disabled={isSubmitting}
                    onClick={resetForm}
                    classes={{ root: classes.button }}
                  >
                    Reset
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    variant='contained'
                    color='primary'
                    disabled={!isValid || isSubmitting}
                    onClick={submitForm}
                    classes={{ root: classes.button }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}

AdminDashboardFilters.propTypes = {
  refetch: PropTypes.func.isRequired,
}

export default memo(AdminDashboardFilters);
