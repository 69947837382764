import React, { memo, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { get, noop } from 'lodash';
import { scroller } from 'react-scroll';
import Button from './Button';

const scrollToServicesOffered = () => {
  scroller.scrollTo('services-offered', {
    delay: 0,
    smooth: 'easeInOutQuart'
  });
}

const ServicesOfferedButton = ({ isHomePage }) => {
  const history = useHistory();
  const location = useLocation();
    
  const onClick = () => {
    // We could've also used 'history.location' (the path should be '/')
    // or maybe wait for scroller to complain the id doesn't exist
    if (isHomePage) {
      scrollToServicesOffered();
    } else {
      history.push({
        pathname: '/',
        state: { autoScrollToServicesOffered: true },
      });
    }
  }

  useEffect(() => {
    const autoScrollToServicesOffered = !!get(location, 'state.autoScrollToServicesOffered');

    if (autoScrollToServicesOffered) {
      scrollToServicesOffered();

      // Remove that state variable:
      const { pathname, ...rest } = location;
      history.replace(pathname, { autoScrollToServicesOffered: false, ...rest });
    }
    
    return noop;
  }, [location, history]);

  return <Button onClick={onClick}>Services Offered</Button>
}

export default memo(ServicesOfferedButton)
