import { SAVE_ANSWERS, CLEAR_ANSWERS } from '../constants';

export const saveAnswers = ({ examKey, currentPage, answers = [] }) => ({
  type: SAVE_ANSWERS,
  payload: { examKey,currentPage, answers },
});

export const clearAnswers = examKey => ({
  type: CLEAR_ANSWERS,
  payload: examKey,
});
