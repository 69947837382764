import React, { memo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { EXAM_TYPE } from '@nereus/shared/constants';
import { checkIfAdmin } from 'reduxConfig/selectors';
import Page from './_TrialExamOrReviewPage';
import Footer from './_FooterTrialExam';

const TrialExamPage = () => {
  const params = useParams();
  const isAdmin = useSelector(checkIfAdmin);
  //^trial exams are intended to be taken while not logged in
  // so this variable is probably false
  const examId = Number(params.examId);

  return (
    <Page
      examKey={`${EXAM_TYPE.trial}_${examId}`}
      queryVariables={{ examId }}
      performLiveChecking={isAdmin}
      ResultsFooter={Footer}
    />
  );
}

export default memo(TrialExamPage);
