import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';

import { COURSES } from 'constants/queries';
import Loading from 'components/common/Loading';
import Course from './Course';
import {
  NAME_COLUMN_XS,
  CODE_COLUMN_XS,
  BUTTONS_COLUMN_XS,
} from './constants';
import { useQuery, renderComponentOrFunction } from 'utils';

const useStyles = makeStyles((theme) => ({
  // fixes scrollbar in dialog
  courseItemContainer: {
    margin: 0,
  },
}));

const Courses = ({
  Header,
  query,
  queryOptions,
  courses: coursesFromProp,
  hideHeader,
  ...rest
}) => {
  const classes = useStyles();
  const { data, LoadingProps } = useQuery(query, queryOptions);
  const courses = coursesFromProp
    ? coursesFromProp
    : get(data, 'courses', []);
  
  return (
    <Loading {...LoadingProps}>
      <Grid container item spacing={8} classes={{ root: 'bg-gray-200' }}>
        {renderComponentOrFunction(Header)}

        {
          !hideHeader && (
            <Grid container item xs={12} justify="space-between" alignItems="center" className="pb-4">
              <Grid item xs={NAME_COLUMN_XS}>
                <Typography variant='h6'>
                  Course Name
                </Typography>
              </Grid>

              <Grid item xs={CODE_COLUMN_XS}>
                <Typography variant='h6'>
                  Course ID
                </Typography>
              </Grid>

              <Grid item xs={BUTTONS_COLUMN_XS} />
            </Grid>
          )
        }

        <Grid container item xs={12} spacing={8} classes={{ root: classes.courseItemContainer }}>
          {courses.map(course => (
            <Course
              key={course.code}
              course={course}
              {...rest}
            />
          ))}
        </Grid>
      </Grid>
    </Loading>
  )
}

Courses.propTypes = {
  Header: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  query: PropTypes.object,
  queryOptions: PropTypes.object,
  courses: PropTypes.arrayOf(PropTypes.object),
  hideHeader: PropTypes.bool,
}

Courses.defaultProps = {
  query: COURSES,
  hideHeader: false,
}

export default memo(Courses);
