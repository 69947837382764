import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Route from './_Route';
import { getAuthToken } from 'reduxConfig/selectors';

const PublicRoute = props => {
  const token = useSelector(getAuthToken);

  const additionalProps = [
    'noHeader' in props ? null : 'header',
    'sidebar' in props ? null : 'noSidebar',
  ].filter(x => x).reduce((acc, x) => ({...acc, [x]: true}), {});

  return token
    ? <Redirect to='/courses' />
    : <Route {...additionalProps} {...props} />
}

export default PublicRoute;
