import { combineReducers } from 'redux';
import currentUser from './currentUser';
import savedExamAnswers from './savedExamAnswers';
import misc from './misc';

const rootReducer = combineReducers({
  currentUser,
  savedExamAnswers,
  misc,
})

export default rootReducer;
