import { createMachine, assign } from 'xstate'


export const SettingsMachine = createMachine({
  id: 'SettingsMachine',
  initial: 'idle',
  context: {
    form: {
      courses: [],
      secondsPerQuestion: 60,
      passingExamRate: 70,
      passingCourseRate: 60,
      exams: [],
      numberOfQuestions: 50
    }
  },
  on: {
    SET_COURSE_MAX_ATTEMPT: {
      actions: ['setCourseMaxAttempt']
    },
    SET_COURSES: {
      actions: ['setCourses']
    },
    SET_PASSIING_EXAM_RATE: {
      actions: ['setPassingExamRate']
    },
    SET_PASSIING_COURSE_RATE: {
      actions: ['setPassingCourseRate']
    },
    SET_EXAM_MAX_QUESTION: {
      actions: ['setExamMaxQuestion']
    },
    SET_NUMBEROFQUESTIONS: {
      actions: ['setNumberofquestions']
    },
    SET_EXAMS: {
      actions: ['setExams']
    },
    SET_SECONDS_PER_QUESTION: {
      actions: ['setSecondsPerQuestion']
    },
    SAVE_CHANGES: 'save.submitting',
    IDLE: 'idle',
    FETCHING: 'idle.fetching',
    FAILED: 'idle.failed',
    SAVE_FAILED: 'save.failure',
    SAVE_SUCCESS: 'save.success'
  },
  states: {
    idle: {
      initial: 'normal',
      states: {
        normal: {},
        fetching: {},
        failed: {}
      }
    },
    save: {
      initial: 'idle',
      states: {
        idle: {},
        submitting: {},
        success: {},
        failure: {},
        critical: {}
      }
    }
  }
}, {
  guards: {
    // code here
  },
  actions: {
    // code here
    setSecondsPerQuestion: assign({
      form(ctx, event) {
        return {
          ...ctx.form,
          secondsPerQuestion: event.data
        }
      }
    }),
    setCourseMaxAttempt: assign({
      form: (ctx, event) => {
        const form = ctx.form
        let courses = [...form.courses]
        for(var i=0; i<courses.length; i++) {
          let course = courses[i]
          if(course.id == event.data.id) {
            // console.log(course)
            courses[i] = {...courses[i], maxExamAttempts: Number(event.data.value), updated: true}
            break;
          }
        }

        return { ...form, courses}
      }
    }),
    setCourses: assign({
      form: (ctx, event) => {
        const form = ctx.form
        return { ...form, courses: event.data}
      }
    }),
    setPassingExamRate: assign({
      form(ctx, event) {
        const form = ctx.form
        return {...form, passingExamRate: event.data} 
      }
    }),
    setPassingCourseRate: assign({
      form(ctx, event) {
        const form = ctx.form
        return {...form, passingCourseRate: event.data} 
      }
    }),
    setExams: assign({
      form(ctx, event) {
        const form = ctx.form
        return {...form, exams: event.data} 
      }
    }),
    setNumberofquestions: assign({
        form(ctx, event) {
          const form = ctx.form
          const value = Number(event.data)
          if(value > 100) {
            value = 100
          }
          if(value < 1) {
            value = 1
          }
          return {...form, numberOfQuestions: value}
        }
    }),
    setExamMaxQuestion: assign({
      form(ctx, event) {
        let exams = [...ctx.form.exams]
        // console.log('exams', exams)
        for(var i=0; i<exams.length; i++) {
          if(exams[i].id == event.data.id) {
            exams[i] = {...exams[i], maxQuestionsToUse: Number(event.data.value), updated: true}
            break
          }
        }
        return {...ctx.form, exams: exams}
      }
    })
  },
  services: {
    // code here
    savingChanges(ctx, event) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          console.log('resolve')
          resolve(true)
        }, 3000)
      })
    }
  }
})