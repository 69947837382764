import React, { memo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const Link = memo(({ to, children, ...rest }) => (
  <RouterLink
    to={to}
    className='text-blue-500 underline'
    {...rest}
  >
    {children}
  </RouterLink>
))

const FooterTrialExam = () => {
  return (
    <Grid
      container
      item
      justify='center'
      className='bg-blue-200 rounded-md border-blue-600 border mt-5'
    >
      <Typography variant='h5' className='text-center'>
        Thank you for trying out our trial exam! If you wish to continue reviewing and take our examination, please <Link to='/register'>register</Link> or <Link to='/login'>login</Link> if you already have an account.
      </Typography>
    </Grid>
  );
}

export default memo(FooterTrialExam);
