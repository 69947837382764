import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { noop } from 'lodash';

const DenyButton = ({
  id,
  openModal,
  ...rest
}) => {
  return (
    <Button
      variant='contained'
      color='secondary'
      onClick={() => openModal(id)}
      {...rest}
    >
      Reject
    </Button>
  )
}

DenyButton.propTypes = {
  id: PropTypes.number.isRequired,
  openModal: PropTypes.func.isRequired,
}

DenyButton.defaultProps = {
  openModal: noop,
}

export default memo(DenyButton);
