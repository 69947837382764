import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PieChart as PieChartBase } from 'react-minimal-pie-chart';

import Count from './Count';
import Label from './Label';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 32,
  },
}));

const LINE_WIDTH = 60;

const renderLabel = ({ dataEntry }) => {
  if (dataEntry.value <= 0) return '';
  
  return Math.round(dataEntry.percentage) + '%';
}

const PieChart = ({
  passed,
  failed,
  noResponse,
}) => {
  const classes = useStyles();

  const data = [
    { label: 'Passed', value: passed, color: '#2C6DB5' },
    { label: 'Failed', value: failed, color: '#35C78A' },
    { label: 'No response', value: noResponse, color: '#40B8CB' },
  ]

  const chartData = passed + failed + noResponse === 0
    ? data.map(x => ({ ...x, value: 1 })) // so a chart will be visible even if all are zero
    : data;

  return (
    <Grid component={Paper} container classes={{ root: classes.container }}>
      <Grid item>
        <Typography variant='h6'>Examinees</Typography>
      </Grid>

      <Grid item container>
        <Grid item container xs={1} />

        <Grid item container xs={8}>
          <PieChartBase
            data={chartData}
            radius={PieChartBase.defaultProps.radius - 6}
            lineWidth={LINE_WIDTH}
            label={renderLabel}
            labelPosition={100 - LINE_WIDTH / 2}
            labelStyle={{
              fill: '#fff',
              opacity: 0.75,
              pointerEvents: 'none',
              fontSize: '50%',
            }}
            segmentsStyle={{ transition: 'stroke .3s', cursor: 'pointer' }}
            segmentsShift={1}
            animate
          />
        </Grid>

        <Grid item container xs={3} spacing={3} direction='column' alignItems='flex-end'>
          {data.map(row => <Count key={row.label} {...row} />)}
        </Grid>
      </Grid>

      <Grid item container justify='center'>
        {data.map(row => <Label key={row.label} {...row} />)}
      </Grid>
    </Grid>
  )
}

PieChart.propTypes = {
  passed: PropTypes.number,
  failed: PropTypes.number,
  noResponse: PropTypes.number,
}

PieChart.defaultProps = {
  passed: 0,
  failed: 0,
  noResponse: 0,
}

export default memo(PieChart);
