import { createContext } from 'react';
import DefaultActions from './DefaultActions';

export const CONTEXT_DEFAULT = {
  isOpen: false,
  message: '',
  title: '',
  actions: DefaultActions,
}

export default createContext(CONTEXT_DEFAULT);
