import { SET_ITEM, SET_SITE_VISITORS } from '../constants';

const DEFAULT_STATE = {
  siteVisitors: 0,
};

export default (state = DEFAULT_STATE, { type, payload }) => {
  switch (type) {
    case SET_ITEM: {
      return {
        ...state,
        ...payload,
      };
    }

    case SET_SITE_VISITORS: {
      return {
        ...state,
        siteVisitors: Math.max(state.siteVisitors, payload),
      }
    }

    default:
      return state
  }
}
