import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table as TableBase,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
} from '@material-ui/core';
import { isString } from 'lodash';
import { sentenceCase } from 'change-case';
import memoize from 'memoize-one';
import useKeypress from 'react-use-keypress';

import { displayDate as d } from 'utils';
import TableDataRow from './TableDataRow';

const EXAMPLE_COLUMNS = [
  'username',
  { field: 'email' },
  { field: 'birthday', label: 'Cake Day', format: d },
  { label: 'Buttons', render: row => <div>xxx</div> },
  { label: 'Forty-two', get: row => 42 }
];

const EXAMPLE_ROWS = [
  { id: 1, username: 'Foo', email: 'foo@example.com', birthday: new Date() },
  { id: 2, username: 'Bar', email: 'bar@example.com', birthday: '1970-12-25' },
]

const DEFAULT_ROWS_PER_PAGE = 10;

const getColumns = memoize((columns = []) => columns.map(column => {
  if (isString(column)) return { field: column, label: sentenceCase(column) };

  return {
    hide: false,
    ...column,
    label: column.label || sentenceCase(column.field)
  }
}).filter(x => x && !x.hide && x.label && (x.field || x.get || x.render)));

const paginateRows = memoize(({
  rows = [],
  page = 1,
  rowsPerPage = DEFAULT_ROWS_PER_PAGE,
  disablePagination = false,
}) => {
  if (disablePagination) return rows;

  const start = Math.max(0, page * rowsPerPage);
  const end = start + rowsPerPage;

  return rows.slice(start, end);
});

const Table = ({
  columns: inputColumns,
  rows: unpaginatedRows,
  renderRowExpand,
  disablePagination,
  rowsPerPage,
}) => {
  // Reminder: "page" is ZERO-indexed:
  const [page, setPageNoVerify] = useState(0);
  const setPage = page => {
    const lastPage = Math.ceil(unpaginatedRows.length / rowsPerPage) - 1;

    setPageNoVerify(Math.min(Math.max(0, page), lastPage));
  }

  useKeypress(['ArrowLeft', 'ArrowRight'], (event) => {
    if (event.key === 'ArrowLeft') {
      setPage(page - 1);
    } else if (event.key === 'ArrowRight') {
      setPage(page + 1);
    }
  });

  const handleChangePage = (event, page) => setPage(page)

  const columns = getColumns(inputColumns);
  const rows = paginateRows({
    rows: unpaginatedRows,
    page,
    rowsPerPage,
    disablePagination,
  });
  
  return (<>
    <TableContainer component={Paper}>
      <TableBase stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell key={column.label}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableDataRow
              key={row.id}
              row={row}
              columns={columns}
              renderRowExpand={renderRowExpand}
            />
          ))}
        </TableBody>
      </TableBase>
    </TableContainer>

    {
      !disablePagination && (
        <TablePagination
          component="div"
          rowsPerPageOptions={[]}
          rowsPerPage={rowsPerPage}
          count={unpaginatedRows.length}
          page={page}
          onChangePage={handleChangePage}
        />
      )
    }
  </>)
}

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  renderRowExpand: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  disablePagination: PropTypes.bool,
  rowsPerPage: PropTypes.number,
}

Table.defaultProps = {
  columns: EXAMPLE_COLUMNS,
  rows: EXAMPLE_ROWS,
  disablePagination: false,
  rowsPerPage: DEFAULT_ROWS_PER_PAGE,
}

export default memo(Table);
