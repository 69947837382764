import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

const ConfirmActions = ({ closeDialog, submit }) => {
  const handleClickCancel = closeDialog;
  
  const handleClickSubmit = () => {
    closeDialog();
    submit();
  }

  return (<>
    <Button onClick={handleClickCancel} color='secondary' autoFocus>
      Cancel
    </Button>

    <Button onClick={handleClickSubmit} color='primary' variant='contained'>
      Submit
    </Button>
  </>)
}

ConfirmActions.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
}

export default memo(ConfirmActions);
