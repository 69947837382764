import PublicOrPrivateRoute from './PublicOrPrivateRoute';
import AdminRoute from './AdminRoute';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

export default PublicOrPrivateRoute;

export {
  AdminRoute,
  PrivateRoute,
  PublicRoute,
}
