import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import Loading from 'components/common/Loading';
import { GET_USER_TAKING_EXAM_FOR_COURSE_ID as QUERY } from 'constants/queries';
import { updateUserTakingExamForCourseId } from 'reduxConfig/actions/currentUser';
import { getAuthToken, checkIfCanTakeExams, checkIfUserCurrentlyTakingExam } from 'reduxConfig/selectors';
import { useQuery } from 'utils';

const ExamModeLoading = ({
  children,
  requiredExamMode,
  examRedirect,
  skipExamModeCheck: skipExamModeCheckProp,
}) => {
  const dispatch = useDispatch();
  const token = useSelector(getAuthToken);
  const canTakeExams = useSelector(checkIfCanTakeExams);
  const isUserCurrentlyTakingExam = useSelector(checkIfUserCurrentlyTakingExam);

  const skipExamModeCheck =
    skipExamModeCheckProp !== undefined
      ? skipExamModeCheckProp
      : (!token || requiredExamMode === undefined);

  // Once we have sockets, etc we can probably remove this
  const { loading, error, LoadingProps } = useQuery(QUERY, {
    skip: skipExamModeCheck,
    errorMessage: 'Error checking your exam status',
    onCompleted: data => {
      dispatch(updateUserTakingExamForCourseId(get(data, 'currentUser.takingExamForCourseId')));
    },
  });
  
  if (canTakeExams && !skipExamModeCheck && !error && !loading) {
    if (requiredExamMode !== isUserCurrentlyTakingExam) {
      const url = examRedirect || (isUserCurrentlyTakingExam ? `/exam-list` : '/courses');

      return <Redirect to={url} />
    }
  }

  return (
    <Loading {...LoadingProps}>
      {children}
    </Loading>
  )
}

ExamModeLoading.propTypes = {
  requiredExamMode: PropTypes.bool,
  examRedirect: PropTypes.string,
}

export default memo(ExamModeLoading);
