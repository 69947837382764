import React, { useEffect } from 'react';
// Material
import Chip from '@material-ui/core/Chip';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
// Color
import red from '@material-ui/core/colors/red';
// Icon
import ReplayIcon from '@material-ui/icons/Replay';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import ErrorIcon from '@material-ui/icons/Error';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
// Machine
import { SettingsMachine } from './machines'
import { useMachine } from '@xstate/react'
// Style
import { makeStyles } from '@material-ui/core/styles';
// GraphQL
import {
  useQuery as useQueryBase,
  gql
} from '@apollo/client';
// import { SET_SETTING, SET_EXAM_SETTING } from 'constants/mutations';
import { SET_SETTING } from 'constants/mutations';
import { useQuery, useMutation } from 'utils';
//
import { useSnackbar } from 'notistack';

const useGetSetup = (options) => {
  const query = gql`
    query {
      allExams {
        id
        name
        maxQuestionsToUse
        maxTrialQuestionsToUse
        visible
        courseCategory {
          course {
            name
          }
        }
      }
      courses {
        id
        name
        maxExamAttempts
      }
      settings {
        id
        numberOfExams
        passingCourseRate
        passingExamRate
        secondsPerQuestion
      }
    }
  `
  const settings = useQueryBase(query, options)
  return settings
}

const useStyles = makeStyles({
  card: {
    width: '100%',
    marginBottom: '10px'
  },
  input: {
    width: 42,
  },
});

export default function SettingsPage () {
  // const classes = useStyles()
  const Machine = useMachine(SettingsMachine)
  const setup = useGetSetup({
    onCompleted: (response) => {
      // console.log(response)
      Machine[1]({type: 'SET_EXAMS', data: response.allExams})
      Machine[1]({type: 'SET_COURSES', data: response.courses})
      Machine[1]({type: 'SET_NUMBEROFQUESTIONS', data: response.settings?.numberOfExams || 50})
      Machine[1]({type: 'SET_PASSIING_EXAM_RATE', data: response.settings?.passingExamRate || 70})
      Machine[1]({type: 'SET_PASSIING_COURSE_RATE', data: response.settings?.passingCourseRate || 60})
      Machine[1]({type: 'SET_SECONDS_PER_QUESTION', data: response.settings?.secondsPerQuestion || 60})
      Machine[1]('IDLE')
    },
    onError: (error) => {
      Machine[1]('FAILED')
    }
  })

  // console.log(setup)
  // Machine[1]('IDLE')
  
  useEffect(() => {
    Machine[1]('FETCHING')
  }, [])
  // useEffect(() => {
  //   console.log(Machine[0])
  // }, [Machine])
  return (
    <>
      {
        Machine[0].matches('idle.fetching') && <Loading />
      }
      {
        Machine[0].matches('idle.failed') && <Failure />
      }
      {
        !['idle.fetching', 'idle.failed'].some(Machine[0].matches) && <Content Machine={Machine} />
      }
    </>
  )
}


const Loading = ({}) => {
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <CardContent style={{display: 'flex', alignItems: 'center', flexDirection: 'column', padding: '50px 0'}}>
        <CircularProgress />
        <span>Please wait. . .</span>
      </CardContent>
    </Card>
  )
}

const Failure = ({
  Machine
}) => {
  const classes = useStyles()
  const handleReload = () => {
    window.location.reload()
  }
  return (
    <Card className={classes.card}>
      <CardContent style={{display: 'flex', alignItems: 'center', flexDirection: 'column', padding: '50px 0'}}>
        <ErrorIcon color="secondary" style={{fontSize: '3em'}}/>
        <span style={{color: red[500]}}>Failed, Please tyy again. <IconButton><ReplayIcon /></IconButton></span>
      </CardContent>
    </Card>
  )
}


const SnackFailure = ({
  open,
  onClose,
  message = 'Oops! Something went wrong.'
}) => {
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={onClose} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
      <MuiAlert elevation={6} variant="filled" severity="error" onClose={onClose}>
        {message}
      </MuiAlert>
    </Snackbar>
  )
}
const SnackSuccess = ({
  open,
  onClose,
  message = 'Request success!'
}) => {
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={onClose} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
      <MuiAlert elevation={6} variant="filled" severity="success" onClose={onClose}>
        {message}
      </MuiAlert>
    </Snackbar>
  )
}

const SliderWithTextField = ({
  name,
  subTitle = '',
  value,
  disabled = false,
  onChange = () => {},
  step = 10,
  min = 1,
  max = 100,
  icon = <PlaylistAddCheckIcon />
}) => {
  const classes = useStyles()
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography id="input-slider" gutterBottom>
          {name}
        </Typography>
        {
          Boolean(subTitle) && (
            <Typography >
              <Chip label={subTitle} />
            </Typography>
          )
        }
        <Grid container spacing={2}>
          <Grid item>
            { icon }
          </Grid>
          <Grid item xs>
            <Slider
              disabled={disabled}
              value={value}
              onChange={(e, newVal) => onChange(newVal)}
              aria-labelledby="input-slider"
              step={step}
              min={min}
              max={max}
            />
          </Grid>
          <Grid item>
            <Input
              disabled={disabled}
              className={classes.input}
              value={value}
              margin="dense"
              onChange={e => onChange(Number(e.target.value))}
              onBlur={e => onChange(Number(e.target.value))}
              inputProps={{
                step,
                min,
                max,
                type: 'number',
                'aria-labelledby': 'input-slider'
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const Content = ({
  Machine
}) => {
  const classes = useStyles()
  const [setSettings] = useMutation(SET_SETTING, { autoSuccessErrorMessages: false });
  const handleSubmit = () => {
    Machine[1]({type: 'SAVE_CHANGES'})
    setSettings({
      variables: {
        secondsPerQuestion: Machine[0].context.form.secondsPerQuestion,
        numberOfQuestions: Machine[0].context.form.numberOfQuestions,
        passingCourseRate: Machine[0].context.form.passingCourseRate,
        passingExamRate: Machine[0].context.form.passingExamRate,
        exams: Machine[0].context.form.exams.filter(item => item.updated),
        courses: Machine[0].context.form.courses.filter(item => item.updated)
      }
    })
      .then(response => {
        Machine[1]('SAVE_SUCCESS')
      })
      .catch(error => {
        Machine[1]('SAVE_FAILED')
      })
  }
  return (
    <div>
      <SnackSuccess open={['save.success'].some(Machine[0].matches)} onClose={() => Machine[1]('IDLE')} message="Settings updated!"/>
      <SnackFailure open={['save.failure', 'save.critical'].some(Machine[0].matches)} onClose={() => Machine[1]('IDLE')}/>
      <Card className={classes.card}>
        <CardHeader title="Passing rates" />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            This will setup thing to your examineers.
          </Typography>
          <Grid container>
            <Grid item xs={6}>
              <SliderWithTextField name="Passing Course Rate (%)"
                value={Machine[0].context.form.passingCourseRate}
                disabled={Machine[0].matches('save.submitting')}
                onChange={value => Machine[1]({type: 'SET_PASSIING_COURSE_RATE', data: value})}
              />
            </Grid>
            <Grid item xs={6}>
              <SliderWithTextField name="Passing Exam Rate (%)"
                value={Machine[0].context.form.passingExamRate}
                disabled={Machine[0].matches('save.submitting')}
                onChange={value => Machine[1]({type: 'SET_PASSIING_EXAM_RATE', data: value})}
              />
            </Grid>
            <Grid item xs={12}>
              <SliderWithTextField name="Seconds per questions"
                max={360}
                icon={<QueryBuilderIcon />}
                value={Machine[0].context.form.secondsPerQuestion}
                disabled={Machine[0].matches('save.submitting')}
                onChange={value => Machine[1]({type: 'SET_SECONDS_PER_QUESTION', data: value})}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardHeader title="Courses max attetmpts" />
        <CardContent>
          <Grid container>
          {
            Machine[0].context.form.courses.length &&
            Machine[0].context.form.courses.map((course, index) => {
              return (
                <Grid item xs={12} key={'course-field '+index}>
                  <SliderWithTextField name={course.name}
                    step={1}
                    max={10}
                    value={course.maxExamAttempts}
                    disabled={Machine[0].matches('save.submitting')}
                    onChange={value => Machine[1]({type: 'SET_COURSE_MAX_ATTEMPT', data: {id: course.id, value}})}/>
                </Grid>
              )
            })
          }
          </Grid>
        </CardContent>
      </Card>

      <Card className={classes.card}>
        <CardHeader title="Functions max questions" />
        <CardContent>
          <Grid container>
          {
            Machine[0].context.form.exams.length &&
            Machine[0].context.form.exams.map((exam, index) => {
              return (
                <Grid item xs={12} key={'exam-field '+index}>
                  <SliderWithTextField name={exam.name} subTitle={window._.get(exam, 'courseCategory.course.name', '')}
                    step={1}
                    value={exam.maxQuestionsToUse}
                    disabled={Machine[0].matches('save.submitting')}
                    onChange={value => Machine[1]({type: 'SET_EXAM_MAX_QUESTION', data: {id: exam.id, value}})}/>
                </Grid>
              )
            })
          }
          </Grid>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardActions style={{display: 'flex', justifyContent: 'flex-end'}}>
          <Tooltip title="Reset to default">
            <Button disabled={Machine[0].matches('save.submitting')} size="small" color="secondary">
              RESET
            </Button>
          </Tooltip>
          <Button disabled={Machine[0].matches('save.submitting')} size="small" color="primary" variant="contained" onClick={handleSubmit}>
            {
              Machine[0].matches('save.submitting') ? <span>SAVING. . .</span> : <span>SAVE CHANGES</span>}
          </Button>
        </CardActions>
      </Card>
    </div>
  )
}