import { SET_USER, UPDATE_USER, CLEAR_USER } from '../constants';

export const setUser = (payload = {}) => ({
  type: SET_USER,
  payload,
});

export const login = ({ token, user }) => setUser({ token, ...user });

export const updateUser = (payload = {}) => ({ type: UPDATE_USER, payload });

export const updateUserTakingExamForCourseId = (takingExamForCourseId = null) => ({
  type: UPDATE_USER,
  payload: { takingExamForCourseId },
});

export const clearUser = () => ({ type: CLEAR_USER });

export const logout = clearUser; // alias
