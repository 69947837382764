import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { COURSE_APPROVALS as QUERY } from 'constants/queries';
import { UPDATE_USERCOURSE_SCHED } from 'constants/mutations';
import { useMutation } from 'utils';
import SetSchedModal from './SetSchedModal';
import { useGlobalDialog } from 'globalDialog';

const SetSchedButton = ({
  id,
  userId,
  name,
  ...rest
}) => {
  const { enqueueSnackbar } = useSnackbar();

  // Set Schedule Action
  // const [mutate] = useMutation(MUTATION, {
  //   enqueueSnackbar,
  //   successMessage: 'Schedule added successfully',
  //   errorMessage: 'Error adding schedule',
  //   update(store, { data: { approveDenyCourseApplication: { id }}}) {
  //     const { courseApprovals } = store.readQuery({ query: QUERY });

  //     store.writeQuery({ query: QUERY, data: { courseApprovals:
  //       courseApprovals.filter(x => x.id !== id)
  //     }});
  //   },
  // });

  const [mutation, { loading: isLoading }, mutationResults] = useMutation(
    UPDATE_USERCOURSE_SCHED,
    {
      onCompleted: (data) => {
        console.log('success');
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const {
    open: openDialog,
    close: closeDialog,
  } = useGlobalDialog();

  // const onClick = () => {
  //   mutation({ variables: { id, schedule: 1 }});
  // }

  const onClick = () => {
    openDialog({
      message: <SetSchedModal closeDialog={closeDialog} userId={userId} id={id}/>,
      title: "Set Exam Schedule",
      actions: false,
    });
  }

  return (
    <Button
      variant='contained'
      className={name == "Update Schedule" ? 'bg-green-400 text-white' : 'bg-indigo-700 text-white'}
      // color={name == "Update Schedule" ? 'success' : 'primary'}
      onClick={() => onClick()}
      {...rest}
    >
      {name}
    </Button>
  )
}

SetSchedButton.propTypes = {
  id: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  name: PropTypes.string,
}

export default memo(SetSchedButton);
