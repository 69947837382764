import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import B from 'components/common/B';

const useStyles = makeStyles(theme => ({
  color: {
    color: props => props.color,
  },
}));

const PieChartCount = ({ value, label, color }) => {
  const classes = useStyles({ color });

  return (
    <Grid item container direction='column'>
      <Grid item>
        <B variant='h6' className={classes.color}>{value}</B>
      </Grid>

      <Grid item>
        <Typography>
          {label}
        </Typography>
      </Grid>
    </Grid>
  )
}

PieChartCount.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
}

export default memo(PieChartCount);
