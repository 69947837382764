import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: 'lightgray',
    padding: props => props.padding,
    minHeight: props => `calc(100% - ${props.padding}px)`,
  },
}));

const LoadingContainer = ({ padding, children }) => {
  const classes = useStyles({ padding });

  return (
    <Grid
      container
      spacing={0}
      alignItems='center'
      justify='center'
      classes={{ root: classes.container }}
    >
      {children}
    </Grid> 
  )
}

LoadingContainer.propTypes = {
  padding: PropTypes.number,
}

LoadingContainer.defaultProps = {
  padding: 8*4,
}

export default memo(LoadingContainer);
