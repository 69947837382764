import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const HEIGHT = '5vh';
const HIGHTLIGHT_PX = '6px';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    minHeight: HEIGHT,
  },
  cardOuter: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    backgroundColor: props => props.color || 'blue',
    paddingLeft: HIGHTLIGHT_PX,
  },
  cardInner: {
    paddingLeft: 32,
    paddingRight: 32,
    backgroundColor: theme.palette.background.paper,
    paddingTop: 16,
    paddingBottom: 16,
  },
  number: {
    color: props => props.color || 'blue',
  },
}));

const LongCard = ({ text, children, color, hide, labelVariant, textVariant }) => {
  const classes = useStyles({ color });

  if (hide) return null;

  return (
    <Grid item>
      <Paper className={`${classes.card} ${classes.cardOuter}`}>
        <Grid
          container
          direction='column'
          justify='center'
          classes={{ root: `${classes.card} ${classes.cardInner}`}}
          spacing={0}
        >
          <Grid container>
            <Typography variant={labelVariant}>
              {text}
            </Typography>
          </Grid>

          <Grid container>
            <Typography variant={textVariant} className={classes.number}>
              {children}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

LongCard.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
  labelVariant: PropTypes.string,
  textVariant: PropTypes.string,
  hide: PropTypes.bool,
}

LongCard.defaultProps = {
  children: 0,
  color: 'blue',
  labelVariant: 'body1',
  textVariant: 'h4',
  hide: false,
}

export default memo(LongCard);
