import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { Fab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PrintIcon from '@material-ui/icons/Print';
import ReactToPrint from 'react-to-print';
import { noop } from 'lodash';

import NoPrint from 'components/common/NoPrint';
import RouteContext from 'components/Route/context';

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(1),
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 100,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const PrintFab = props => {
  const classes = useStyles();

  return (
    <Fab
      variant='extended'
      size='large'
      color='primary'
      aria-label='print'
      className={classes.fab}
      {...props}
    >
      <PrintIcon className={classes.extendedIcon} />

      Print
    </Fab>
  )
}

const PrintButton = ({
  callContextCallbacks,
  onBeforeGetContent: onBeforeGetContentFromProps,
  onAfterPrint: onAfterPrintFromProps,
  ...rest
}) => { 
  const {
    ref,
    onBeforeGetContent: onBeforeGetContentFromContext,
    onAfterPrint: onAfterPrintFromContext,
  } = useContext(RouteContext);
  
  if (!ref) return null;
  
  return (
    <NoPrint>
      <ReactToPrint
        trigger={() => <PrintFab {...rest} />}
        content={() => ref}
        onBeforeGetContent={() => {
          onBeforeGetContentFromProps();
          if (callContextCallbacks) { onBeforeGetContentFromContext() };
        }}
        onAfterPrint={() => {
          onAfterPrintFromProps();
          if (callContextCallbacks) { onAfterPrintFromContext() };
        }}
        {...rest}
      />
    </NoPrint>
  )
}

PrintButton.propTypes = {
  callContextCallbacks: PropTypes.bool,
}

PrintButton.defaultProps = {
  callContextCallbacks: true,
  onBeforeGetContent: noop,
  onAfterPrint: noop,
}

export default memo(PrintButton);
