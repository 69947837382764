import React, { memo, useRef } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker } from 'formik-material-ui-pickers';
import { get } from 'lodash';
import { useSnackbar } from 'notistack';

import { updateProfile as VALIDATION }from '@nereus/shared/validation';
import { RANKS } from 'constants/queries';
import { UPDATE_PROFILE as MUTATION } from 'constants/mutations';
import { login as loginAction } from 'reduxConfig/actions/currentUser';
import { getCurrentUser } from 'reduxConfig/selectors';
import { DATEPICKER_FORMAT } from 'constants/etc';
import { useMutation } from 'utils';
import Field from './_Field';
import Form from './_Form';
import SelectField from './_SelectFieldWithLoading';

const ProfileUpdatePage = () => {
  const dispatch = useDispatch();
  const formRef = useRef();
  const user = useSelector(getCurrentUser);
  const { enqueueSnackbar } = useSnackbar();

  const [mutate] = useMutation(MUTATION, {
    enqueueSnackbar,
    rethrowError: true,
    successMessage: 'Profile edited successfully',
    errorMessage: 'Error editing profile',
    onCompleted: (data, g) => {
      g(data);
      dispatch(loginAction({...get(data, 'updateProfile', {})}));
    },
  });

  const validateForm = () => {
    if (formRef.current) {
      formRef.current.validateForm()
    }
  }

  const initialTouched = Object.keys(VALIDATION.fields)
    .reduce((acc, x) => ({ ...acc, [x]: true }), {});

  return (
    <Form
      innerRef={formRef}
      submitText='Update'
      displayLogo={false}
      initialValues={{
        ...user,
        currentPassword: '',
        newPassword: '',
        newPassword2: '',
        phoneNumber: user.phoneNumber || '',
        rank: user.rankId || '',
      }}
      validationSchema={VALIDATION}
      initialTouched={initialTouched}
      validateOnMount={true}
      enableReinitialize={false}
      onSubmit={async (variables, { setSubmitting, setFieldValue, setFieldTouched }) => {
        try {
          await mutate({ variables: {
            ...variables,
            rank: Number(variables.rank),
          }});

          setFieldTouched('currentPassword', false, false);
          
        } catch(e) { // Do not remove, causes app to crash otherwise
        } finally {
          setFieldValue('currentPassword', '');
          setFieldValue('newPassword', '');
          setFieldValue('newPassword2', '');
          setSubmitting(false);
        }
      }}
    >
      <Grid container item spacing={2}>
        <Grid container item>
          <Field
            label='Current Password*'
            name='currentPassword'
            type='password'
            fullWidth
          />
        </Grid>

        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <Field name='newPassword' type='password' label='New password (leave blank to not change)' />
          </Grid>

          <Grid item xs={6}>
            <Field name='newPassword2' type='password' label='Confirm new password' />
          </Grid>
        
          <Grid item xs={6}><Field name='email' label='Email' /></Grid>

          <Grid item xs={6}><Field name='firstName' label='First Name' /></Grid>

          <Grid item xs={6}><Field name='middleName' label='Middle Name' /></Grid>

          <Grid item xs={6}><Field name='lastName' label='Last Name' /></Grid>

          <Grid item xs={6}><Field name='phoneNumber' label='Phone Number' /></Grid>

          <Grid item xs={6}>
            <Field
              name='birthday'
              label='Birthday'
              component={DatePicker}
              inputVariant='outlined'
              format={DATEPICKER_FORMAT}
            />
          </Grid>
          
          <Grid item xs={6}>
            <SelectField
              query={RANKS}
              responseDataPath='ranks'
              label='Rank*'
              name='rank'
              onCompleted={validateForm}
            />
          </Grid>

          <Grid item xs={6}><Field name='address' label='Address' /></Grid>

          <Grid item xs={6}><Field name='graduatedFrom' label='Graduated from' /></Grid>

          <Grid item xs={6}><Field name='company' label='Company' /></Grid>
          
          <Grid item xs={6}>
            <Field
              name='graduatedOn'
              label='Graduated on*'
              component={DatePicker}
              inputVariant='outlined'
              format={DATEPICKER_FORMAT}
            />
          </Grid>
          
          <Grid item xs={6}><Field name='referredBy' label='Referred by' /></Grid>
        </Grid>
      </Grid>
    </Form>
  )
}

export default memo(ProfileUpdatePage);
