import React from 'react';
import { useSelector } from 'react-redux';

import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import { getAuthToken } from 'reduxConfig/selectors';

const Route = props => {
  const token = useSelector(getAuthToken);
  
  return token
    ? <PrivateRoute {...props} />
    : <PublicRoute {...props} />
}

export default Route;
