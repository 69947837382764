import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { DropzoneArea } from 'material-ui-dropzone';
import { MAX_FILE_SIZE } from '@nereus/shared/constants';

const theme = createMuiTheme({
  overrides: {
    MuiDropzonePreviewList: {
      root: {
        justifyContent: 'center',
        alignItems: 'center',
      },
      imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    }
  }
});

const FileUploaderDropzone = ({
  name,
  setFieldValue,
  ...rest
}) => {
  return (
    <MuiThemeProvider theme={theme}>
      <DropzoneArea
        acceptedFiles={['image/*']}
        dropzoneText={'Drag and drop an image here or click'}
        filesLimit={1}
        maxFileSize={MAX_FILE_SIZE}
        onChange={x => setFieldValue(name, x[0])}
        {...rest}
      />
    </MuiThemeProvider>
  )
}

FileUploaderDropzone.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  receiptImage: PropTypes.string,
}

FileUploaderDropzone.defaultProps = {
  name: 'receiptImage',
}

export default memo(FileUploaderDropzone);
