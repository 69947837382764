import React, { createContext } from 'react';
// import { assign } from 'xstate'
// import { useInterpret } from '@xstate/react';
// import CourseMachine from './machines/courses';

export const GlobalStateContext = createContext({});

export const GlobalStateProvider = (props) => {
  // const CourseService = useInterpret(CourseMachine);

  return (
    <GlobalStateContext.Provider value={{  }}>
      {props.children}
    </GlobalStateContext.Provider>
  )
}

