import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ShowHide from './_ShowHide';

const ExamModeHide = ({ children, dontHideIfTrue, ...rest }) => {
  if (dontHideIfTrue) return children;
  
  return <ShowHide {...rest} requiredExamMode={false}>{children}</ShowHide>
}

ExamModeHide.propTypes = {
  dontHideIfTrue: PropTypes.bool,
}

ExamModeHide.defaultProps = {
  dontHideIfTrue: false,
}

export default memo(ExamModeHide);
