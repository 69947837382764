import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

const DefaultActions = ({ close }) => {
  return (
    <Button onClick={close} color="primary" autoFocus>
      Ok
    </Button>
  )
}

DefaultActions.propTypes = {
  close: PropTypes.func.isRequired,
}

export default memo(DefaultActions);
