import React, { memo, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { get, flatten } from 'lodash';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { COURSE_STATUS as STATUS } from '@nereus/shared/constants';
import PrintButton from 'components/common/PrintButton';
import { PASSING_PERCENT } from '@nereus/shared/constants';

import { useTheme } from '@material-ui/core/styles';
import { Typography, Grid, Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getCurrentUser } from 'reduxConfig/selectors';
import { EXAM_LIST_PAGE as QUERY, GET_USER_SCHEDULE, GET_SCHEDULE } from 'constants/queries';
// import { useQuery } from 'utils';
import { useLazyQuery } from '@apollo/client';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';

import Users from 'components/pages/UsersPage';
import CustomHeader from '../ReportPage/CustomHeader';
import Item from '../ReportPage/Item';
import CourseApprovals from '../ReportPage/CourseApprovals';
import NoPrint from 'components/common/NoPrint';
import QRCode from "react-qr-code";

import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import moment from 'moment';

import { createMachine, assign } from 'xstate'
import { useMachine } from '@xstate/react'

const Machine = createMachine({
  id: 'Result',
  initial: 'idle',
  context: {
    qrCode: '',
    passingRates: 0,
    categories: {},
    functionsWithCompetence: [],
    dateTaken: null,
    error: null,
  },
  states: {
    idle: {
      on: {
        FETCH: 'fetch'
      }
    },
    fetch: {
      on: {
        SUCCESS: {
          target: 'success',
          actions: assign((ctx, event) => {
            const data = event.data
            return {
              ...ctx,
              qrCode: get(data, 'hash', ''),
              passingRates: get(data, 'setting', {}),
              categories: get(data, 'courseCategories', []),
              functionsWithCompetence: get(data, 'functionsWithCompetence', []),
              dateTaken: get(data, 'dateTaken', '(not yet)'),
            }
          })
        },
        FAILURE: {
          target: 'failure',
          actions: [assign({
            error(ctx, event) {
              return event.data
            }
          })]
        }
      }
    },
    success: {},
    failure: {
      on: {
        RETRY: 'fetch'
      }
    },
  }
})

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: 32,
    marginRight: 32,
  },
  "@global": {
    // MUI typography elements use REMs, so you can scale the global
    // font size by setting the font-size on the <html> element.
    html: {
      fontSize: '.8em',
    }
  }
}));

const ExamResultsReportPage = () => {
  const theme = useTheme()
  const classes = useStyles();
  const Service = useMachine(Machine)
  const user = useSelector(getCurrentUser);
  const { courseId, userId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [ schedUsers, setSchedUser ] = useState([]);
  // console.log(user.id);
  let variables = {
    courseId: Number(courseId),
    isForResult: true
  }
  if(userId) {
    variables = {...variables, userId: Number(userId)}
  }
  const [loadResult, { called, loading, data }] = useLazyQuery(QUERY, {
    enqueueSnackbar,
    errorMessage: 'Error fetching your exams',
    variables,
    onCompleted(data, clientOptions) {
      // console.log('onCompleted', data)
      Service[1]({ type: 'SUCCESS', data: data.courseCategoriesWithExamFlag})
      // Service[1]('FAILURE')
    },
    onError(data, clientOptions) {
      const error = data.toString()
      const errors = error.match(/\[(.*)\]/)
      // console.log(error, error.match(/\[(.*)\]/))
      if(errors.length > 1) {
        Service[1]({ type: 'FAILURE', data: errors[1] })
      } else {
        Service[1]({ type: 'FAILURE', data: null })  
      }
    }
  });
  const qrCode = Service[0].context.qrCode
  const passingRates = Service[0].context.passingRates
  const categories = Service[0].context.categories
  const functionsWithCompetence = Service[0].context.functionsWithCompetence
  const dateTaken = Service[0].context.dateTaken
  // const flattenedExams = flatten(categories.map(category => category.exams));
  const flattenedExams = flatten(functionsWithCompetence.map(category => category.items));
  const totalScore = flattenedExams.reduce((sum, x) => sum + x.score, 0);
  const totalItems = flattenedExams.reduce((sum, x) => sum + x.qa, 0);
  const percent = (totalScore / totalItems) * 100;
  // console.log('flattenedExams', flattenedExams)

  // const { loading: gettingSchedule, refetch: refetchSchedule } = useQuery(
  //   GET_USER_SCHEDULE,
  //   {
  //     variables: {userId: parseInt(user.id)},
  //     fetchPolicy: "cache-and-network",
  //     onCompleted: (data) => {
  //       {
  //         setSchedUser(data.getcurrentuserschedule[0].schedule);
  //         console.log(data.getcurrentuserschedule[0].schedule);
  //       }
  //     },
  //     onError: (error) => {
  //       console.log(error);
  //     },
  //   }
  // );

  const handleRetry = () => {
    Service[1]('RETRY')
    loadResult()
  }

  useEffect(() => {
    Service[1]('FETCH')
    loadResult()
  }, []);

  return (<>
    <div className={`${classes.container} bg-gray-200`}>
      <Item>
        <Grid container xs={12} >
          <Grid container justify='center' align='center'>
            <Grid item xs={12} align='left'>
              <small style={{display: 'block'}}>EAD NO.:10-1</small>
              <small style={{display: 'block'}}>Initial Issue Date: 03-20-2022</small>
              <small style={{display: 'block'}}>Provision Date: 04-24-2022</small>
            </Grid>
            <Grid item xs={12} style={{position: 'relative'}}>
              {/*<img
                style={{position: 'absolute', height: '70px'}}
                className={classes.image}
                src='/images/panama-logo-square.png'
                alt='header-logo'
              />*/}
              <small style={{display: 'block'}}><b>REPUBLIC OF THE PHILIPPINES</b></small>
              <small style={{display: 'block'}}><b>DEPARTMENT OF TRANSPORTATION</b></small>
              <div style={{display: 'block', height: '5px', backgroundColor: 'black'}}></div>
              <Typography variant="h6">
                MARITIME INDUSTRY AUTHORITY
              </Typography>
              {/*<img
                style={{position: 'absolute', height: '70px', top: '0', right: '0'}}
                className={classes.image}
                src='/images/protectmarine-logo.png'
                alt='protectmarine-logo'
              />*/}
            </Grid>
            <Grid item xs={12} align="center" style={{position: 'relative', marginTop: 10}}>
              <Typography variant="h4" style={{padding: '24px 0'}}>
                CERTIFICATE OF PASSING
              </Typography>
              <div style={{position: 'absolute', top: 5, right: 20}}>
                <QRCode size={70} title="Examination Result" value={window.location.origin+'/examiner/result?token='+window.encodeURIComponent(qrCode)}/>
              </div>
            </Grid>



            {/*<Grid item xs={4}>
              <img
                className={classes.image}
                src='/images/panama-logo-square.png'
                alt='header-logo'
              />
            </Grid>

            <Grid item xs={4}>
              <Typography variant="h4" className="mt-6">
                Exam Results
              </Typography>
              <Typography variant="subtitle1" className="mt-1">
                OIC of an Engineering Watch
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <img
                className={classes.image}
                src='/images/protectmarine-logo.png'
                alt='protectmarine-logo'
              />
            </Grid>*/}
          </Grid>
        </Grid>
        {
          (() => {
            if(Service[0].matches('fetch')) {
              return (
                <Grid container xs={12} className="mt-2">
                  <Grid item xs={12}>
                    <Typography align="center">
                      <CircularProgress />
                    </Typography>
                  </Grid>
                </Grid>
              )
            }
            if(Service[0].matches('failure')) {
              if(Service[0].context.error === 'NO_EXAM_RECORD') {
                return (
                  <Grid container xs={12} className="mt-2">
                    <Grid item xs={12}>
                      <Paper elevation={6} style={{ padding: theme.spacing(3) }}>
                        <Typography variant="h2" color="error" align="center">Oops!</Typography>
                        <Typography align="center">There are no exam records yet.</Typography>
                        <Typography align="center">
                          <Button color="secondary" onClick={handleRetry}>Retry</Button>
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                )
              }
              return (
                <Grid container xs={12} className="mt-2">
                  <Grid item xs={12}>
                    <Paper elevation={6} style={{ padding: theme.spacing(3) }}>
                      <Typography variant="h2" color="error" align="center">Oops!</Typography>
                      <Typography align="center">Failed to load result.</Typography>
                      <Typography align="center">
                        <Button color="secondary" onClick={handleRetry}>Retry</Button>
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>
              )
            }

            return (
              <div>
                <Grid container xs={12} className="mt-4">
                  <Grid item xs={6}>
                    <Grid>
                      <Typography variant="subtitle1">Examinee: {user.firstName} {user.lastName}</Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={6}>
                    <Grid align='right'>
                      {/*<Typography variant="subtitle1">Examination Date: {schedUsers}</Typography>*/}
                      <Typography variant="subtitle1">Examination Date: {dateTaken}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container xs={12} className="mt-2">
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650, fontSize: '.9em' }} aria-label="simple table" size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell align='center'><b>COMPETENCE</b></TableCell>
                          <TableCell align='center'><b>RATING</b></TableCell>
                          <TableCell align='center'><b>REMARKS</b></TableCell>
                          <TableCell align='center' style={{width: 100}}><b>DATE TAKEN</b></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {functionsWithCompetence.map((category, findex) => {
                          return (
                            <>
                              <TableRow>
                                <TableCell colSpan={4}>
                                  <Typography variant="h6">{category.name}</Typography>
                                </TableCell>
                              </TableRow>
                              {
                                category.items.map((competence, cindex) => (
                                  <TableRow key={'c-'+cindex}>
                                    <TableCell>&nbsp;&nbsp;&nbsp;{competence.name}</TableCell>
                                    <TableCell align="center">{competence.average.toFixed(2)}%</TableCell>
                                    <TableCell align="center">{
                                      competence.average.toFixed(2) >= passingRates.passingExamRate ? <b style={{color: green[500]}}>PASSED</b> : <b style={{color: red[500]}}>FAILED</b>
                                    }</TableCell>
                                    <TableCell align="center">{moment(competence.dateTaken).format('YYYY-MM-DD')}</TableCell>
                                  </TableRow>
                                ))
                              }
                            </>)
                        })}
                        <TableRow>
                          <TableCell>
                            <Typography variant="h6">OVERALL</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="h6">{percent.toFixed(2)}%</Typography>
                          </TableCell>
                          <TableCell align='center'>
                            <Typography variant="h6">
                              {percent > passingRates.passingCourseRate ? <b style={{color: green[500]}}>PASSED</b> : <b style={{color: red[500]}}>FAILED</b> }
                            </Typography>
                          </TableCell>
                          <TableCell align='center'></TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </div>
            )
          })()
        }
      </Item>
    </div>

    <NoPrint><PrintButton /></NoPrint>
  </>)
}

export default memo(ExamResultsReportPage);
