import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useSnackbar } from 'notistack';

import { login as LOGIN_VALIDATION } from '@nereus/shared/validation';
import { LOGIN as LOGIN_MUTATION } from 'constants/mutations';
import { login as loginAction } from 'reduxConfig/actions/currentUser';
import { useMutation } from 'utils';
import Field from './_Field';
import Form from './_Form';

const useStyles = makeStyles((theme) => ({
  image: {
    backgroundImage: "url('/images/main_background.png')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    paddingLeft: 40,
    paddingRight: 40,
    minHeight: '100vh',
  },
}));

const LoginPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [mutate] = useMutation(LOGIN_MUTATION, {
    enqueueSnackbar,
    rethrowError: true,
    successMessage: 'Logging in...',
    errorMessage: 'Error logging in',
    onCompleted: (data, g) => {
      g(data);
      dispatch(loginAction({...get(data, 'login', {})}));
      history.push('/courses');
    },
  });

  return (
    <Form
      containerImageClass={classes.image}
      headerText='Welcome!'
      submitText='Login'
      initialValues={{
        username: '',
        password: '',
      }}
      validationSchema={LOGIN_VALIDATION}
      onSubmit={async (variables, { setSubmitting, setFieldValue }) => {
        try {
          await mutate({ variables });
        } catch(e) {
          setFieldValue('password', '');
          setSubmitting(false);
        }
      }}
    >
      <Grid container item spacing={2}>
        <Grid container item>
          <Field
            name='username'
            label='Username'
            fullWidth
          />
        </Grid>

        <Grid container item>
          <Field
            label='Password'
            name='password'
            type='password'
            fullWidth
          />
        </Grid>
      </Grid>
    </Form>
  )
}

export default memo(LoginPage);
