import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Collapse,
  Grid,
  Paper,
  Typography,
  Button,
} from '@material-ui/core';
import moment from 'moment';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { get, noop } from 'lodash';
import { useQuery } from 'utils';

import { COURSE_STATUS as STATUS } from '@nereus/shared/constants';
import { checkIfCanAlwaysAccessCourse } from 'reduxConfig/selectors';
import ActionButton from './ActionButton';
import TakeExamsButton from './TakeExamsButton';
import Category from './Category';
import {
  NAME_COLUMN_XS,
  CODE_COLUMN_XS,
  BUTTONS_COLUMN_XS,
} from '../constants';
import { useSnackbar } from 'notistack';
import { GET_SCHEDULE } from 'constants/queries';

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: 8*2,
  },
  containerPointer: {
    cursor: 'pointer',
  },
  icon: {
    borderRadius: 60,
    boxShadow: '0px 0px 2px #888',
    padding: '0.5em 0.6em',
    marginLeft: 8*2,
    cursor: 'pointer',
  },
  categoryContainer: {
    marginTop: 8*2,
  },
}));

const Course = ({
  course,
  setCourseUploadingFor,
  startExpanded,
  disableExpandCollapse,
  hideTakeExamButtons,
  inReviewMode,
  hideActionButtons,
  hideActionButtonsIfAccepted,
  showCategoryItems,
  showExamButtonAsDisabledIfNotAccepted: sEBaDinA,
}) => {
  const classes = useStyles();
  const canAlwaysAccessCourse = useSelector(checkIfCanAlwaysAccessCourse);

  const [expanded, setExpanded] = useState(startExpanded);

  const toggleExpand = disableExpandCollapse
    ? noop
    : () => setExpanded(!expanded)

  const status = get(course, 'userCourse.status');
  const categories = get(course, 'categories', []);
  const { enqueueSnackbar } = useSnackbar();

  const hasExams = categories.some(x => x.exams.length > 0);
  const isAccepted = canAlwaysAccessCourse || status === STATUS.accepted;

  const { data, LoadingProps } = useQuery(GET_SCHEDULE, {
    variables: enqueueSnackbar,
    errorMessage: `Error loading course approvals`,
  });

  const rows = get(data, 'getUserSchedule', []);

  useEffect(() => {
    console.log('USE EFFECT!'+ JSON.stringify(rows));
  });
 
  return (
    <Paper
      component={Grid}
      item
      container
      classes={{ root: [
        classes.container,
        disableExpandCollapse ? null : classes.containerPointer,
      ].filter(x => x).join(' ')}}
      onClick={toggleExpand}
    >
      <Grid item container alignItems='center'>
        <Grid item xs={NAME_COLUMN_XS}>
          <Typography variant='subtitle1'>
            {course.name}
          </Typography>
        </Grid>

        <Grid item xs={CODE_COLUMN_XS}>
          <Typography variant='subtitle1'>
            {course.code}
          </Typography>
        </Grid>

        <Grid container item xs={BUTTONS_COLUMN_XS} justify='flex-end'>
          {/* Display schedule only if set */}
          <Typography variant='subtitle1'>
            {rows.map(row => (
              row.schedule && row.courseId == course.id ? <p className='text-sm'>Your exam is scheduled on {row.schedule}</p> : ''
            ))}
          </Typography>
          {rows.map(row => (
            row.schedule !=='' && moment(row.schedule, 'MM-DD-YYYY - h:mm a').isBefore(moment()) && row.courseId == course.id ? 
            <TakeExamsButton
              courseId={Number(course.id)}
              disabled={!isAccepted}
            /> : ''
          ))}

          { !hideActionButtons && (
            <ActionButton
              course={course}
              status={status}
              setCourseUploadingFor={setCourseUploadingFor}
              hideActionButtonsIfAccepted={hideActionButtonsIfAccepted}
            />
          )}
          
          {
            !disableExpandCollapse && (
              <div className={classes.icon}>
                {expanded ? <ExpandLess /> : <ExpandMore />}
              </div>
            )
          }
        </Grid>

        <Collapse
          component={Grid}
          item
          container
          classes={{ container: classes.categoryContainer }}
          in={expanded}
          timeout='auto'
          unmountOnExit
        >
            <Grid item container spacing={2}>
              {categories.map(category => {
                return (
                  <Grid item key={category.id} xs={12}>
                    <Category
                      id={category.id}
                      name={category.name}
                      hasExams={category.exams.length > 0}
                      inReviewMode={inReviewMode}
                      items={category.items}
                      canReview={isAccepted}
                      showCategoryItems={showCategoryItems}
                    />
                  </Grid>
                )
              })}
            </Grid>
        </Collapse>
      </Grid>
    </Paper>
  )
}

Course.propTypes = {
  course: PropTypes.object.isRequired,
  setCourseUploadingFor: PropTypes.func,
  startExpanded: PropTypes.bool,
  disableExpandCollapse: PropTypes.bool,
  hideTakeExamButtons: PropTypes.bool,
  inReviewMode: PropTypes.bool,
  hideActionButtons: PropTypes.bool,
  hideActionButtonsIfAccepted: PropTypes.bool,
  showCategoryItems: PropTypes.bool,
  showExamButtonAsDisabledIfNotAccepted: PropTypes.bool,
}

Course.defaultProps = {
  startExpanded: false,
  disableExpandCollapse: false,
  hideTakeExamButtons: false,
  inReviewMode: false,
  hideActionButtons: false,
  hideActionButtonsIfAccepted: false,
  showCategoryItems: false,
  showExamButtonAsDisabledIfNotAccepted: false,
}

export default memo(Course);
